import {
  MainPageKO,
  MoneyPlexKO,
  GuidePageKO,
  MapPageKO,
  InqeryPageKO,
} from "./ko";
import {
  MainPageCN,
  MoneyPlexCN,
  GuidePageCN,
  MapPageCN,
  InqeryPageCN,
} from "./ch";
import {
  MainPageEN,
  MoneyPlexEN,
  GuidePageEN,
  MapPageEN,
  InqeryPageEN,
} from "./en";
import {
  MainPageJP,
  MoneyPlexJP,
  GuidePageJP,
  MapPageJP,
  InqeryPageJP,
} from "./ja";
import {
  MainPageTW,
  MoneyPlexTW,
  GuidePageTW,
  MapPageTW,
  InqeryPageTW,
} from "./tw";
import {
  MainPageTH,
  MoneyPlexTH,
  GuidePageTH,
  MapPageTH,
  InqeryPageTH,
} from "./th";

const string = {
  KR: {
    moneyplex: "머니플렉스",
    kioskLoation: "키오스크 위치",
    inquery: "빠른 도입문의",
    guide: "이용 가이드",
    event: "이벤트",
    cs: "고객지원",
    kioskSetup: "서울/경기/인천/부산 지점",
    mainKioskFind: " 가까운 환전 키오스크를 쉽게 찾아보세요",
    KioskFindDirect: "키오스크 바로 찾기",
    partner: "파트너사",
    evnet: "진행중인 이벤트",
    closeEvent: "종료된 이벤트",
    discountEvent: "환율 우대 쿠폰 받아가세요!",
    gotoList: "리스트로 가기",
    ...MainPageKO,
    ...MoneyPlexKO,
    ...GuidePageKO,
    ...MapPageKO,
    ...InqeryPageKO,
  },
  EN: {
    moneyplex: "MoneyPlex",
    kioskLoation: "Kiosk Location",
    inquery: "Inquery",
    guide: "Guide",
    event: "Event",
    cs: "Customer Service",
    kioskSetup: "Seoul/Gyeonggi/Incheon/Busan Branches",
    mainKioskFind: "Easily find nearby currency exchange kiosks",
    KioskFindDirect: "Find Kiosk Direct",
    partner: "Partner",
    evnet: "Process events",
    closeEvent: "Closed events",
    discountEvent: "Get your currency exchange discount coupon!",
    gotoList: "Go to the list",
    ...GuidePageEN,
    ...MainPageEN,
    ...MoneyPlexEN,
    ...MapPageEN,
    ...InqeryPageEN,
  },
  CN: {
    moneyplex: "MoneyPlex",
    kioskLoation: "自助机位置",
    inquery: "快速引入咨询",
    guide: "使用指南",
    event: "活动",
    cs: "客户支持",
    kioskSetup: "首尔/京畿/仁川/釜山分店",
    mainKioskFind: "轻松找到附近的外币兑换自助机",
    KioskFindDirect: "立即查找自助机",
    partner: "合作伙伴",
    evnet: "正在进行的活动",
    closeEvent: "已结束的活动",
    discountEvent: "领取您的汇率优惠券！",
    gotoList: "去列表",
    ...GuidePageCN,
    ...MainPageCN,
    ...MoneyPlexCN,
    ...MapPageCN,
    ...InqeryPageCN,
  },
  TH: {
    moneyplex: "MoneyPlex",
    kioskLoation: "ตำแหน่งคีออส",
    inquery: "สอบถามการติดตั้งอย่างรวดเร็ว",
    guide: "คู่มือการใช้งาน",
    event: "กิจกรรม",
    cs: "ฝ่ายบริการลูกค้า",
    kioskSetup: "สาขาโซล/คยองกี/อินชอน/ปูซาน",
    mainKioskFind: "ค้นหาตู้แลกเปลี่ยนเงินตราที่อยู่ใกล้คุณได้อย่างง่ายดาย",
    KioskFindDirect: "ค้นหาคีออสทันที",
    partner: "คู่ค้า",
    evnet: "กิจกรรมที่กำลังดำเนินอยู่",
    closeEvent: "กิจกรรมที่สิ้นสุดแล้ว",
    discountEvent: "รับคูปองส่วนลดอัตราแลกเปลี่ยน!",
    gotoList: "ไปที่รายการ",
    ...GuidePageTH,
    ...MainPageTH,
    ...MoneyPlexTH,
    ...MapPageTH,
    ...InqeryPageTH,
  },
  CT: {
    moneyplex: "MoneyPlex",
    kioskLoation: "自助機位置",
    inquery: "快速引入諮詢",
    guide: "使用指南",
    event: "活動",
    cs: "客戶支持",
    kioskSetup: "首爾/京畿/仁川/釜山分店",
    mainKioskFind: "輕鬆找到附近的外幣兌換自助機",
    KioskFindDirect: "立即查找自助機",
    partner: "合作夥伴",
    evnet: "正在進行的活動",
    closeEvent: "已結束的活動",
    discountEvent: "領取您的匯率優惠券！",
    gotoList: "去列表",
    ...MainPageTW,
    ...MoneyPlexTW,
    ...GuidePageTW,
    ...MapPageTW,
    ...InqeryPageTW,
  },
  JP: {
    moneyplex: "MoneyPlex",
    kioskLoation: "キオスク位置",
    inquery: "迅速導入のお問い合わせ",
    guide: "利用ガイド",
    event: "イベント",
    cs: "カスタマーサポート",
    kioskSetup: "ソウル/京畿/仁川/釜山支店",
    mainKioskFind: "近くの外貨両替キオスクを簡単に探しましょう",
    KioskFindDirect: "キオスクをすぐに探す",
    partner: "パートナー",
    evnet: "現在進行中のイベント",
    closeEvent: "終了したイベント",
    discountEvent: "為替レートの割引クーポンをゲット！",
    gotoList: "リストに行く",
    ...GuidePageJP,
    ...MainPageJP,
    ...MoneyPlexJP,
    ...MapPageJP,
    ...InqeryPageJP,
  },
};

export default string;

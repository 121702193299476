export const MainPageTW = {
  MaintitleBody1: "與預付交通卡一起",
  MaintitleBody2: "外幣兌換 MoneyPlex！",
  MaintitleSubBody: "現在體驗智能又方便的兌換服務",
  MaincardSectionTitle: "預付交通卡發行",
  MaincardSectionBody1: "在 MoneyPlex 發行，",
  MaincardSectionBody2: "從公共交通到購物，",
  MaincardSectionBody3: "隨時隨地簡單便捷支付",
  MaincardSectionBody4: "適合國內外人士使用",
  MainappSectionTitle: "快速方便的兌換預約及多種合作商優惠券福利！",
  MainappSectionSubBody: "MoneyPlex 還會幫助管理兌換後剩餘的零錢",
  MainkioskSectionTitle: "可發行和充值預付交通卡的多功能自助機",
  MainkioskSectionBody:
    "支持100種貨幣，6種語言，具備發行和充值預付交通卡功能，並支持免稅店、百貨公司和酒店會員卡的多功能自助機",
  MainpartnerSectionTitle: "合作夥伴",
  MainpartnerSectionBody1: "“我們在酒店和免稅店提供便捷的兌換機,",
  MainpartnerSectionBody2: "以增加收入，所有運營由我們負責。”",
  MainpartnerSectionInqueryButton: "申請諮詢",
  MainmobileTilte1: "兌換的新標準，MoneyPlex！",
  MainmobileTilte2: "請到現場直接確認。",
};

export const MoneyPlexTW = {
  MoneyplexTabLeft: "MoneyPlex 客戶",
  MoneyplexTabRight: "商務諮詢",
  MoneyplexKioskTitle:
    "還在四處找兌換外幣的地方嗎？現在就來體驗 MoneyPlex 兌換自助機吧",
  MoneyplexExchangeProcess: "兌換流程",
  MoneyplexExchange1Title: "步驟 1：選擇要兌換的外幣",
  MoneyplexExchange1Body:
    "支持多種外幣，您可以選擇美元（USD）、歐元（EUR）、日元（JPY）、人民幣（CNY）等15個主要國家的貨幣",
  MoneyplexExchange2Title: "步驟 2：掃描身份證（護照）",
  MoneyplexExchange2Body:
    "身份證（護照）信息是必需的，這是韓國進行外幣兌換的基本程序",
  MoneyplexExchange3Title: "步驟 3：投入外幣紙鈔",
  MoneyplexExchange3Body:
    "現在是投入您選擇的外幣到自助機的時候了，投入的紙鈔會自動識別並計算兌換金額",
  MoneyplexExchange4Title: "步驟 4：領取韓元",
  MoneyplexExchange4Body:
    "兌換完成後，您可以在自助機領取韓元。兌換金額會立即以現金支付，並會打印收據",
  MoneyplexAppTitle:
    "MoneyPlex APP 提供多種功能，從兌換到積分支付應有盡有，通過一個應用輕鬆兌換，隨時隨地使用",
  MoneyplexApp1Title: "搜尋自助機位置",
  MoneyplexApp1Body:
    "通過 MoneyPlex APP，您可以實時查詢附近的兌換自助機位置，方便您快速兌換外幣",
  MoneyplexApp2Title: "MoneyPlex 會員",
  MoneyplexApp2Body:
    "MoneyPlex APP 用戶可通過簡單的會員認證享受各種福利。完成會員認證後，您可以在積分累積及兌換預約時享有額外的折扣優惠（僅限韓國）",
  MoneyplexApp3Title: "MoneyPlex 外幣兌換預約（僅限韓國）",
  MoneyplexApp3Body:
    "MoneyPlex APP 的外幣兌換預約功能專為韓國用戶設計，您可以提前以優惠的匯率預約兌換外幣，當匯率達到您想要的水準時，便可方便快捷地完成兌換",
  MoneyplexApp4Title: "積分商店優惠券（僅限韓國）",
  MoneyplexApp4Body:
    "MoneyPlex 會員可以使用積分購買合作夥伴的優惠券，積分通過兌換累積，享受各種優惠福利",
  MoneyplexBusinessTitle: "國內最佳無人外幣兌換自助機解決方案",
  MoneyplexBusinessBody:
    "提供24小時運營、降低成本、提升客戶滿意度等，為合作夥伴提供最佳解決方案",
  MoneyplexFindKiosk: "查找自助機位置",
  MoneyplexBenefitTitle1: "24小時可運營",
  MoneyplexBenefitBody1:
    "“顧客無需擔心 時間限制，隨時兌換外幣，延長 服務時間並 最大限度地提 升顧客服務 滿意度。”",
  MoneyplexBenefitTitle2: "預付交通卡&合作商戶卡支持",
  MoneyplexBenefitBody2:
    "“不僅可以發放預付交通卡，還可以發行會員專用卡，讓客戶的選擇範圍更廣。”",
  MoneyplexBenefitTitle3: "提升客戶滿意度",
  MoneyplexBenefitBody3:
    "“特別是在有大量外國遊客的地方，如酒店、賭場、大型超市，自 助機能 極大地提升顧客 的便利性。”",
  MoneyplexBenefitTitle4: "安裝及維護簡便",
  MoneyplexBenefitBody4:
    "“安裝簡單，維護工作最小化，合作夥 伴可以輕鬆運營，無需複 雜的管理 流程。”",
  MoneyplexBenefitTitle5: "創造收入機會",
  MoneyplexBenefitBody5:
    "“安裝自助機 不僅能 帶來 設備收入，還可以因客 流量增加而創造更多 額外收入。”",
  MoneyplexPartnerBenefitHeader: "合作夥伴福利",
  MoneyplexPartnerBenefitHeader2: "MoneyPlex 自助機安裝情況及合作夥伴福利",
  MoneyplexPartnerBenefitTitle1: "提升客戶滿意度",
  MoneyplexPartnerBenefitBody1: "提供24小時外幣兌換服務",
  MoneyplexPartnerBenefitTitle2: "降低成本",
  MoneyplexPartnerBenefitBody2: "無人工成本，實現自動化運營系統",
  MoneyplexPartnerBenefitTitle3: "創造額外收入",
  MoneyplexPartnerBenefitBody3: "通過增加客流量創造更多銷售額",
  MoneyplexPartnerBenefitTitle4: "管理簡便",
  MoneyplexPartnerBenefitBody4: "通過運營管理系統實時監控故障",
  MoneyplexKioskExampleTitle: "自助機安裝案例",
  MoneyplexKioskExamplelocation1: "酒店 • 賭場",
  MoneyplexKioskExamplebody1: "安裝於首爾主要酒店和賭場。",
  MoneyplexKioskExamplelocation2: "港口 • 火車站",
  MoneyplexKioskExamplebody2: "安裝於郵輪港、首爾站等。",
  MoneyplexKioskExamplelocation3: "百貨公司 • 大型超市",
  MoneyplexKioskExamplebody3: "在全國23家大型超市安裝。",
  MoneyplexCustmerReview: "客戶評價",
  MoneyplexCustmerTitle: "實際用戶評價與成功案例",
};

export const GuidePageTW = {
  GuideKioskBill: "支持貨幣",
  GuideKioskCurrencyExchange: "外幣兌換",
  GuideKioskPoint: "積分累積",
  GuideKioskPointSub: "應用積累/條碼積累",
  GuideKioskCardIssue: "預付卡發行與充值",
  GuideKioskRecive: "領取方式",

  GuideAppReservation: "外幣兌換預約",
  GuideAppSignUp: "註冊",
  GuideAppCoupon: "下載優惠券",
  GuideDetailAppConponText1: "01 底部優惠券標籤",
  GuideDetailAppConponText2: "02 輸入優惠券代碼",
  GuideDetailAppConponText3: "03 優惠券註冊完成",
  GuideDetailAppConponText4: "04 優惠券可用",
  GuideDetailAppReservation1: "01 外幣兌換預約",
  GuideDetailAppReservation2: "02 選擇分店",
  GuideDetailAppReservation3: "03 選擇兌換日期",
  GuideDetailAppReservation4: "04 選擇金額和貨幣",
  GuideDetailAppReservation5: "05 提交兌換預約",
  GuideDetailAppReservation6: "06 預約完成",
  GuideDetailAppReservation7: "07 管理預約",
  GuideDetailAppReservation8: "08 存款完成",
  GuideDetailAppReservation9: "09 自助服務機領取",
  GuideDetailKioskBill1: "01 掃描護照",
  GuideDetailKioskBill2: "02 支持貨幣",
  GuideDetailKioskCurrencyExchange1: "01 選擇外幣",
  GuideDetailKioskCurrencyExchange2: "02 確認支持貨幣",
  GuideDetailKioskCurrencyExchange3: "03 投入紙幣",
  GuideDetailKioskCurrencyExchange4: "04 確認紙幣金額",
  GuideDetailKioskPointSubTitle1: "應用積累 - MoneyPlex 手機充值",
  GuideDetailKioskPointSubTitle2: "KB 錢包",
  GuideDetailKioskPointSubTitle3: "領取找零",
  GuideDetailKioskPointSubTitle4: "捐贈",
  GuideDetailKioskPointApp1: "選擇 MoneyPlex 手機充值",
  GuideDetailKioskPointApp2: "選擇會員",
  GuideDetailKioskPointApp3: "會員 - 條碼",
  GuideDetailKioskPointApp4: "非會員 - 輸入電話號碼",
  GuideDetailKioskPointApp5: "非會員 - 輸入電話號碼",
  GuideDetailKioskPointApp6: "充值完成",
  GuideDetailKioskKb1: "選擇國民錢包",
  GuideDetailKioskKb2: "同意",
  GuideDetailKioskKb3: "Kb國民錢包",
  GuideDetailKioskKb4: "選擇國民錢包",
  GuideDetailKioskKb5: "條形碼標籤",
  GuideDetailKioskKb6: "充電完成",
  GuideDetailKioskCoin1: "選擇接收韓元",
  GuideDetailKioskCoin2: "選擇找零",
  GuideDetailKioskCoin3: "選擇會員",
  GuideDetailKioskCoin4: "會員 - 條碼",
  GuideDetailKioskCoin5: "非會員 - 輸入電話號碼",
  GuideDetailKioskCoin6: "非會員 - 輸入電話號碼",
  GuideDetailKioskCoin7: "充值完成",
  GuideDetailKioskReturn1: "選擇接收韓元",
  GuideDetailKioskReturn2: "選擇捐贈",
  GuideDetailKioskReturn3: "捐贈完成",
  GuideDetailCardInfo: "預付卡可以在Klook提前申請並發放。",
  GuideDetailSignUp1: "01 註冊",
  GuideDetailSignUp2: "02 同意",
  GuideDetailSignUp3: "03 電子郵件驗證",
  GuideDetailSignUp4: "04 設置密碼",
  GuideDetailSignUp5: "05 設置安全密碼",
  GuideDetailSignUp6: "06 註冊完成",

  GuideDetailReceiveMoneyTitle1: "現金領取",
  GuideDetailReciveMoneyTitle2: "머니플렉스 앱 충전",

  GuideDetailReceiveMoneySubTitle1: "找零領取",
  GuideDetailReceiveMoney1_1: "01 選擇韓元收款",
  GuideDetailReceiveMoney1_2: "02 選擇找零收款方式",
  GuideDetailReceiveMoney1_3: "03 選擇會員/非會員",
  GuideDetailReceiveMoney1_4: "04 輸入號碼/掃描條形碼",
  GuideDetailReceiveMoney1_5: "05 收取韓元",
  GuideDetailReceiveMoney1_6: "06 完成",

  GuideDetailReceiveMoneySubTitle2: "捐贈（找零）",
  GuideDetailReceiveMoney2_1: "01 選擇現金領取",
  GuideDetailReceiveMoney2_2: "02 選擇找零領取",
  GuideDetailReceiveMoney2_3: "03 選擇捐贈",
  GuideDetailReceiveMoney2_4: "04 完成",

  GuideDetailReceiveMoneySubTitle3: "MoneyFlex應用充電",
  GuideDetailReceiveMoney3_1: "01 交通卡充值",
  GuideDetailReceiveMoney3_2: "02 選擇會員或非會員",
  GuideDetailReceiveMoney3_3: "03 非會員 - 輸入號碼",
  GuideDetailReceiveMoney3_4: "04 會員 - 輸入條碼",
  GuideDetailReceiveMoney3_5: "05 完成",

  GuideDetailReceiveMoneySubTitle4: "KB國民錢包",
  GuideDetailReceiveMoney4_1: "01 選擇KB國民錢包",
  GuideDetailReceiveMoney4_2: "02 同意個人資訊",
  GuideDetailReceiveMoney4_3: "03 掃描條碼",
  GuideDetailReceiveMoney4_4: "04 充值完成",

  GuideDetailReceiveMoneySubTitle5: "捐贈（全額捐贈）",
  GuideDetailReceiveMoney5_1: "01 選擇捐贈",
  GuideDetailReceiveMoney5_2: "02 捐贈完成",

  GuideToolTip1: "請在下方的優惠券標籤中註冊。\n(針對外國人)",
  GuideToolTip2: "登錄時需要設置會員密碼。",
  GuideToolTip3: "需要設置6位數的安全密碼。\n(在使用應用和積分時)",
  GuideToolTip4: "可選擇3個工作日後的日期",
  GuideToolTip5:
    "您需要將兌換金額存入賬戶。\n當天24點之前的入金才有效。\n(由於匯率波動，超過24點後將被取消)",
  GuideToolTip6: "入金完成後，如果請求取消或退款，將會扣除1%的手續費。",
  GotoKlook: "訪問 klook",
  HighlightText: "天24點",
  GuideBill: "紙幣",
  GuideCoin: "硬幣",
  GuideOldBank: "舊鈔票",
  GuideNewBank: "新鈔票",
};
export const MapPageTW = {
  서울: "Seoul",
  부산: "Busan",
  경기: "Gyeonggi",
  인천: "Incheon",
  MapList: "List",
  MapMap: "Map",
  MapOpenTime: "營業時間",
  MapMapButton: "地圖",
  MapLength: "個網點",
  MapForiegn: "Foriegn Exchange",
};
export const InqeryPageTW = {
  InqueryFormName: "公司名稱",
  InqueryFormNumber: "聯絡電話",
  InqueryFormPrivate: "同意收集個人資訊",
  InqueryPlaceHolderName: "請輸入公司名稱",
  InqueryPlaceHolderNumber: "請輸入您的聯絡電話。",
  InqueryPrivateText: "您輸入的資訊不會用於任何超出指定範圍的目的。",
  InqueryTitle1: "請輕鬆諮詢關於引入",
  InqueryTitle2: "MoneyPlex的問題!",
  InqueryApply: "申請",
  CSTitle: "我們能為您做些什麼？",
  CSPlaceHolder: "如果您有任何疑問，可以嘗試搜索關鍵詞。",
  InqueryCheckText: "同意收集個人資訊（必填）",
  CSFAQ: "常見問題",
  CsAnnouncements: "通告",
  CSTotal: "Toal",
  CSKiosk: "Kiosk",
  CSETC: "ETC",

  CSMoneyplex: "MoneyPlex APP",
  InquerySuccess: "您的咨詢已成功發送。",
  CSNotFound: "沒有找到結果。",
  AnnounceNotFound: " 没有公告。",
};

import React, { useEffect, useState } from "react";
import HomeImg from "asset/image/HomeImage.png";
import AppStore from "asset/image/appstoreButton.png";
import PlayStore from "asset/image/googlePlayButton.png";
import Inquery from "asset/image/inquery.png";
import KioskLocationButton from "asset/image/kioskLocationButton.png";
import { Link } from "react-router-dom";
import MoblieHome from "asset/image/MobileHome.png";
import { fontSize24, fontSize30, fontSize40 } from "const/fontSize";
import string from "locales";
import { LanguageProps } from "types/Type";
import KioskButtonBack from "asset/image/MobileKioskFind.png";
import Arrow from "asset/image/KioskArrow.png";
import HomeImg2 from "asset/image/HomePage2.png";

import SimpleImageSlider from "react-simple-image-slider";

export default function MainImgComponent({ userLanguage }: LanguageProps) {
  const isMobile = window.innerWidth < 600 ? true : false;

  return isMobile ? (
    <div
      className="w-full"
      style={{
        backgroundImage: `url(${MoblieHome})`,
        backgroundSize: "cover",
        height: 600,
      }}
    ></div>
  ) : (
    <div>
      <div>
        <SimpleImageSlider
          width={"calc(100vw - 15px)"}
          height={"100vh"}
          images={[{ url: HomeImg }, { url: HomeImg2 }]}
          showNavs={false}
          loop={true}
          showBullets={false}
          autoPlay
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "12%",
        }}
      >
        <div
          style={{
            lineHeight: "150%",
          }}
          className={`${fontSize40} font-bold`}
        >
          {string[userLanguage].MaintitleBody1}
          <br />
          {string[userLanguage].MaintitleBody2}
        </div>

        <div className={`${fontSize24} mt-13`}>
          {string[userLanguage].MaintitleSubBody}
        </div>
        <div className="flex flex-row gap-x-[15px] w-full my-[40px]">
          <Link to={"https://moneyplex.page.link/main"} target="_blank">
            <img
              style={{
                width: "100%",
                objectFit: "cover",
              }}
              src={AppStore}
            />
          </Link>
          <Link to={"https://moneyplex.page.link/main"} target="_blank">
            <img
              style={{
                width: "100%",
              }}
              src={PlayStore}
            />
          </Link>
        </div>
        <Link to={"/kiosklocation"}>
          <div
            className="p-5 py-20"
            style={{
              backgroundImage: `url(${KioskButtonBack})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: 10,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 50,
            }}
          >
            <div
              className={` ${fontSize30} flex justify-center items-center gap-x-10 font-bold text-white`}
            >
              {string[userLanguage].MoneyplexFindKiosk}
              <img
                style={{
                  objectFit: "cover",
                  width: "10%",
                }}
                src={Arrow}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

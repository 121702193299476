import React, { useEffect, useState } from "react";
import SearchIcon from "asset/image/SearchIcon.png";
import { FaqApp, FaqETC, FaqKiosk, FaqTotal } from "const/FaqList";
import { fontSize24, fontSize36 } from "const/fontSize";
import { LanguageCode } from "types/Type";
import string from "locales";

interface FaqItem {
  title: string;
  body: string;
}

interface FaqData {
  KR: FaqItem[];
}

export default function SearchDiv({
  searchWord,
  setSearchWord,
  setFaqList,
  userLanguage,
  debouncedValue,
  setDebouncedValue,
}: {
  searchWord: string;
  setSearchWord: any;
  setFaqList: any;
  userLanguage: LanguageCode;
  debouncedValue: string;
  setDebouncedValue: any;
}) {
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchWord(debouncedValue);
    }, 500); // 500ms delay for debouncing

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, setSearchWord]);

  useEffect(() => {
    if (searchWord === "") {
      setFaqList(FaqTotal);
    } else {
      setFaqList(() => {
        const filterFaq: FaqItem[] = [];
        FaqTotal[userLanguage].forEach((v: FaqItem) => {
          if (v.title.includes(searchWord) || v.body.includes(searchWord)) {
            filterFaq.push(v);
          }
        });
        console.log(filterFaq);
        return filterFaq;
      });
    }
  }, [searchWord]);
  return (
    <div
      className="flex flex-col  justify-center bg-primary p-[5%]"
      style={{ marginBottom: 60 }}
    >
      <span className={`${fontSize36}`}>{string[userLanguage].CSTitle}</span>
      <div className="pl-[24px] pr-[10px] flex items-center justify-center py-10 bg-white  mt-[30px] rounded-5  md:mx-[30%] md:rounded-10 md:py-20 ">
        <img
          style={{
            marginRight: 15,
          }}
          className=""
          width={20}
          height={20}
          src={SearchIcon}
        ></img>
        <input
          onChange={(e) => {
            setDebouncedValue(e.target.value);
          }}
          value={debouncedValue}
          style={{
            outline: "none",
            boxShadow: "none",
          }}
          className={`w-full ${fontSize24} `}
          placeholder={string[userLanguage].CSPlaceHolder}
        />
      </div>
    </div>
  );
}

import React from "react";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import MobileBackground from "asset/image/MobileBackground.png";
import MessageIcon from "asset/image/messageIcon.png";
import { fontSize30, fontSize32, leading50 } from "const/fontSize";
import Background from "asset/image/Background.png";
import { Link } from "react-router-dom";

export default function InqueryBanner({
  body1Text,
  body2Text,
  inqueryText,
}: {
  body1Text: string;
  body2Text?: string;
  inqueryText: string;
}) {
  const widthSize = useWindowSizeCustom();
  return widthSize.width < 800 ? (
    <div
      className="w-full flex flex-col items-center justify-between py-20 px-24 "
      style={{
        backgroundImage: `url(${MobileBackground})`,
        backgroundSize: "cover",
        marginTop: 30,
      }}
    >
      <img src={MessageIcon} width={40} />
      <span className="ml-[25px] my-13 text-[12px] text-white font-bold leading-20">
        "빠르고 간편한 도입 문의, 지금 바로 신청 해보세요.
        <br />
        당신의 비즈니스에 최적화된 솔루션을 제공합니다."
      </span>
      <button className="w-full bg-white  rounded-[50px] text-14 font-bold text-primary p-10 h-40">
        도입 문의하기
      </button>
    </div>
  ) : (
    <div className=" relative mt-80 px-[10%] text-center">
      <div
        className="top-0 relative w-full justify-around flex flex-row items-center px-[5%] py-[1.5%] 2xl:h-[240px] xl:h-[250px] "
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          borderRadius: "80px 80px 0px 0px",
        }}
      >
        <div className="flex flex-row w-[50%] ">
          <img
            style={{
              width: "12%",
              objectFit: "contain",
            }}
            src={MessageIcon}
          />
          <span
            className={`${fontSize32} ml-25 mt-20  text-white ${leading50} font-bold`}
          >
            {body1Text}
          </span>
        </div>
        <Link to={"/inquery"}>
          <button
            className={` min-w-[300px]  ${fontSize30} text-nowrap bg-white  rounded-[50px] py-20 px-10  font-bold text-primary`}
          >
            {inqueryText}
          </button>
        </Link>
      </div>
    </div>
  );
}

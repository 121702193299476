import { useEffect, useState } from "react";
import { LanguageCode } from "types/Type";
const useUserLanguage = (): [
  LanguageCode,
  React.Dispatch<React.SetStateAction<LanguageCode>>
] => {
  const [userLanguage, setUserLanguage] = useState<LanguageCode>("KR");
  useEffect(() => {
    const userLanguage = localStorage.getItem("lang");
    console.log(userLanguage, 111111);
    if (userLanguage === null) {
      localStorage.setItem("lang", "KR");
      setUserLanguage("KR");
    } else {
      setUserLanguage(userLanguage as LanguageCode);
    }
  }, [userLanguage]);
  return [userLanguage, setUserLanguage];
};

export default useUserLanguage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "asset/image/logo.png";
import White from "asset/image/worldWhite.png";
import World from "asset/image/world.png";
import { useLocation } from "react-router-dom";
import MobileLogo from "asset/image/mobileLogo.png";
import MobileBar from "asset/image/mobile_bar.png";
import MobileLan from "asset/image/mobile_lan.png";
import MobileBarBlack from "asset/image/MobileBarBlack.png";
import MobileLanBlack from "asset/image/MobileLanBlack.png";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import string from "locales";
import useUserLanguage from "hooks/useUserLanguage";
import { GLOBLE_COLOR } from "const/const";
import { LanguageCode } from "types/Type";
export default function Header({
  userLanguage,
  setUserLanguage,
}: {
  userLanguage: LanguageCode;
  setUserLanguage: any;
}) {
  const [isTransrateHeader, setIsTransrateHeader] = useState(false);
  const windowSize = useWindowSizeCustom();
  const location = useLocation();
  const mobile = windowSize.width < 900 ? true : false;
  const [clickMenu, setClickMenu] = useState(false);
  const [preScrollY, setPreScrollY] = useState(-1);
  const [currentPage, setCurrentPage] = useState("");
  const [webClickMenu, setWebClickMenu] = useState(false);
  const [mobileClickMenu, setMobileClickMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(userLanguage);

  const preventScroll = () => {
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.overflowY = "scroll";
  };

  const allowScroll = () => {
    document.body.style.position = "";
    document.body.style.width = "";
    document.body.style.top = "";
    document.body.style.overflowY = "";
    window.scrollTo(0, preScrollY);
  };

  useEffect(() => {
    if (clickMenu) {
      preventScroll();
    }
    return () => {
      allowScroll();
    };
  }, [clickMenu]);
  useEffect(() => {
    setCurrentLanguage(userLanguage);
  }, [userLanguage]);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location);
    setCurrentPage(location.pathname.split("/")[1]);
    if (location.pathname !== "/") {
      setIsTransrateHeader(true);
      return;
    } else {
      setIsTransrateHeader(false);
    }

    const scrollHandler = () => {
      setPreScrollY(window.scrollY);
      if (window.scrollY > 5) {
        setIsTransrateHeader(true);
      } else {
        setIsTransrateHeader(false);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [location]);
  return mobile ? (
    <>
      <div
        style={{
          background: isTransrateHeader || clickMenu ? "white" : "transparent",
          zIndex: 10,
          top: 0,
          left: 0,
          height: 75,
          maxHeight: 75,
        }}
        className="px-24 py-15 w-full fixed flex"
      >
        <div className="w-full flex flex-row justify-between items-center ">
          {windowSize.width > 600 ? (
            <img
              style={{
                objectFit: "cover",
                height: 20,
              }}
              src={Logo}
              height={20}
            />
          ) : (
            <img
              style={{
                objectFit: "cover",
                height: 20,
              }}
              src={isTransrateHeader || clickMenu ? Logo : MobileLogo}
              height={20}
            />
          )}

          <div className="flex flex-row flex-shrink-0 gap-x-10 justify-around ">
            {mobileClickMenu ? (
              <div
                onClick={() => {
                  setMobileClickMenu(false);
                }}
                style={{
                  backgroundColor: GLOBLE_COLOR.PRIMARY,
                }}
                className="flex-shrink-0  relative  mt-[-10px] pt-10 px-10 rounded-t-20 rounded-b-20"
              >
                <img
                  style={{
                    objectFit: "contain",
                    width: 25,
                    minWidth: 25,
                    minHeight: 25,
                  }}
                  src={isTransrateHeader ? MobileLanBlack : MobileLan}
                />
                <div
                  style={{
                    backgroundColor: GLOBLE_COLOR.PRIMARY,
                  }}
                  className="flex flex-col py-15 gap-y-20  left-0 text-center text-16 rounded-10"
                >
                  {["KR", "EN", "JP", "CN", "CT", "TH"].map((v, i) => {
                    return (
                      <div
                        className="font-bold "
                        onClick={() => {
                          localStorage.setItem("lang", v);
                          setUserLanguage(v as LanguageCode);
                        }}
                        style={{
                          color: currentLanguage === v ? "white" : "#C99C52",
                          textDecoration: "underline",
                        }}
                      >
                        {v}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <img
                className="px-10"
                onClick={() => {
                  setMobileClickMenu(true);
                  setClickMenu(false);
                }}
                style={{
                  objectFit: "contain",
                  width: 45,
                  minWidth: 25,
                  minHeight: 25,
                }}
                src={
                  isTransrateHeader || clickMenu ? MobileLanBlack : MobileLan
                }
              />
            )}
            <img
              onClick={() => {
                setClickMenu((prev) => !prev);
                setMobileClickMenu(false);
              }}
              style={{
                objectFit: "contain",
                width: 24,
                height: 24,
              }}
              src={isTransrateHeader || clickMenu ? MobileBarBlack : MobileBar}
            />
          </div>
        </div>
        {clickMenu && (
          <div
            onClick={() => {
              setClickMenu(false);
            }}
            className="bg-white flex flex-col gap-y-28 mx-[-25px] px-25 pt-30 text-18 h-[100vh] "
          >
            <Link to={"/moneyplex"}>
              <span className="">{string[userLanguage].moneyplex}</span>
            </Link>
            <Link to={"/kiosklocation"}>
              <span>{string[userLanguage].kioskLoation}</span>
            </Link>
            <Link to={"/inquery"}>
              <span>{string[userLanguage].inquery}</span>
            </Link>
            <Link to={"/guide"}>
              <span>{string[userLanguage].guide}</span>
            </Link>
            <span>{string[userLanguage].event}</span>
            <Link to={"/custermerservice"}>
              <span>{string[userLanguage].cs}</span>
            </Link>
          </div>
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        position: "fixed",
        height: 75,
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: isTransrateHeader ? "white" : "transparent",
        zIndex: 99999,
        boxShadow: isTransrateHeader
          ? "0px 6px 6.1px 0px rgba(0, 0, 0, 0.12)"
          : "",
      }}
      className="flex flex-row h-[70px] items-center justify-around  border border-blue-500"
    >
      <Link to={"/"} className="">
        <div>
          <img
            style={{
              minHeight: 30,
              minWidth: 127,
            }}
            src={Logo}
            width={122}
            height={30}
          />
        </div>
      </Link>

      <div
        style={{
          color: isTransrateHeader ? "#1E2637" : "white",
        }}
        className="flex flex-row justify-evenly text-nowrap  items-center text-20 font-medium"
      >
        <Link to={"/moneyplex"}>
          <div
            className={`${
              currentPage === "moneyplex" && "text-primary"
            }  pr-45`}
          >
            {string[userLanguage].moneyplex}
          </div>
        </Link>
        <Link to={"/kiosklocation"}>
          <div
            className={`${
              currentPage === "kiosklocation" && "text-primary"
            }  pr-45`}
          >
            {string[userLanguage].kioskLoation}
          </div>
        </Link>
        <Link to={"/inquery"} className="flex-grow">
          <div
            className={`${currentPage === "inquery" && "text-primary"}  pr-45`}
          >
            {string[userLanguage].inquery}
          </div>
        </Link>
        <Link to={"/guide"}>
          <div
            className={`${currentPage === "guide" && "text-primary"}  pr-45`}
          >
            {string[userLanguage].guide}
          </div>
        </Link>
        <Link to={"/event"}>
          <div
            className={`${currentPage === "event" && "text-primary"}  pr-45`}
          >
            {string[userLanguage].event}
          </div>
        </Link>
        <Link to={"/custermerservice"}>
          <div
            className={`${
              currentPage === "custermerservice" && "text-primary"
            }  pr-45`}
          >
            {string[userLanguage].cs}
          </div>
        </Link>
        {webClickMenu ? (
          <div
            onClick={() => {
              setWebClickMenu(false);
            }}
            style={{
              backgroundColor: GLOBLE_COLOR.PRIMARY,
            }}
            className="flex-shrink-0  relative px-10 pt-10 mt-[-10px] rounded-t-20 mr-45"
          >
            <img src={isTransrateHeader ? World : White} />
            <div
              style={{
                backgroundColor: GLOBLE_COLOR.PRIMARY,
              }}
              className="flex flex-col py-15 gap-y-20 absolute w-47 left-0 text-center text-16 rounded-b-20 text-[#C99C52]"
            >
              {["KR", "EN", "JP", "CN", "CT", "TH"].map((v, i) => {
                return (
                  <div
                    className="font-bold "
                    onClick={() => {
                      localStorage.setItem("lang", v);
                      setUserLanguage(v as LanguageCode);
                    }}
                    style={{
                      color: currentLanguage === v ? "white" : "#C99C52",
                      textDecoration: "underline",
                    }}
                  >
                    {v}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <span
            onClick={() => {
              setWebClickMenu(true);
            }}
            style={{
              minWidth: 27,
              minHeight: 27,
            }}
            className="flex-shrink-0 px-10 pr-55 rounded-t-20"
          >
            <img src={isTransrateHeader ? World : White} />
          </span>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./map.css";
import Map from "./Map";
import string from "locales";
import { LanguageCode } from "types/Type";
export default function KioskMap({
  kioskData,
  userLanguage,
  currentLanguage,
  selectMarker,
  setSelectedMarker,
}: {
  kioskData: any;
  userLanguage: LanguageCode;
  currentLanguage: string;
  selectMarker: any;
  setSelectedMarker: any;
}) {
  const [selectCategory, setSelectCategory] = useState(
    string[userLanguage as "EN"]["서울"]
  );

  useEffect(() => {
    if (kioskData && selectMarker === "") {
      setSelectedMarker(kioskData[string[userLanguage as "EN"]["서울"]]?.[0]);
    }
  }, [userLanguage]);
  const [map, setMap] = useState<any>();

  return (
    <div className="mt-[60px]">
      <div className="flex justify-end">
        <Map
          kioskData={kioskData}
          selectedMarker={selectMarker}
          setSelectedMarker={setSelectedMarker}
          map={map}
          setMap={setMap}
          userLanguage={userLanguage}
          currentLanguage={currentLanguage}
        />
        <div className=" ml-[15px]">
          <div className="flex flex-row gap-x-[50px] text-[24px] mr-[45px]">
            {[
              string[userLanguage as "EN"]["서울"],
              string[userLanguage as "EN"]["경기"],
              string[userLanguage as "EN"]["인천"],
              string[userLanguage as "EN"]["부산"],
            ].map((v, i) => {
              return (
                <div
                  onClick={() => {
                    setSelectCategory(v);
                  }}
                  className="flex flex-col items-center w-[47px] justify-center font-semibold "
                >
                  <div
                    className={`${
                      selectCategory === v ? "text-primary" : "text-[#A2AAB4]"
                    } `}
                  >
                    {v}
                  </div>
                  {selectCategory === v && (
                    <div
                      className={`  relative top-2 w-[47px] h-[2px] bg-primary`}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mb-[23px] mt-[40px] text-[24px]">
            {kioskData[selectCategory]?.length}
            {string[userLanguage].MapLength}
          </div>
          {kioskData[selectCategory]?.map((v: any, index: number) => {
            return (
              <div
                onClick={() => {
                  setSelectedMarker(v);
                  map?.setCenter({ lat: +v.kiosk_map_y, lng: +v.kiosk_map_x });
                  map?.setZoom(15);
                }}
                className={`hoverItem ${
                  v.kiosk_key === selectMarker?.kiosk_key && "clickItem"
                }`}
                style={{
                  width: "90%",
                }}
              >
                <div className="flex flex-row items-center text-[18px] py-[14px]">
                  <img
                    style={{
                      marginRight: 10,
                    }}
                    src={v?.kiosk_logo_url}
                    width={26}
                    height={26}
                  />
                  {`${v[`kiosk_name${currentLanguage as "_en"}`]}`}
                </div>
                {index !== kioskData[selectCategory]?.length - 1 && (
                  <div
                    style={{
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const FaqApp = {
  KR: [
    {
      title: "머니플렉스 앱은 어디서 다운 받을 수 있나요?",
      body: "iOS는 앱스토어, AOS(안드로이드)는 플레이스토어에서 한글로 '머니플렉스', 영어로 'moneyplex'를 검색하시면 다운 받으실 수 있습니다.",
    },

    {
      title: "앱 가입하려면 어떤 과정을 진행해야 하나요?",
      body: "머니플렉스 모바일App 은 내국인(한국인)의 경우 이메일 인증과 패스인증을 통해 이용 가능합니다. 외국인의 경우 이메일 인증만 진행하시면 가입이 완료 됩니다.",
    },
    {
      title: "로그인 비밀번호를 분실했습니다.",
      body: "머니플렉스 모바일App 을 실행 하신 후, 메인화면의 로그인 버튼을 누르신 후 나타나는 화면에서 '비밀번호 찾기'에서 찾아보실 수 있습니다.",
    },
    {
      title: "로그인 비밀번호 변경은 어떻게 하나요?",
      body: "앱 하단의 마이페이지 메뉴에서 이메일 정보를 터치 하시면 이메일 비밀번호 변경 메뉴에서 진행하실 수 있습니다.",
    },
    {
      title: "보안 비밀번호 변경은 어떻게 하나요?",
      body: "앱 하단의 마이페이지 메뉴에서 이메일 정보를 터치 하시면 보안 비밀번호 변경 메뉴에서 진행하실 수 있습니다.",
    },
    {
      title: "핸드폰번호 및 이름 변경은 어떻게 하나요?",
      body: "탈퇴 후 재가입이 필요합니다. 재가입 하시면 기존에 가지고 계셨던 포인트를 새 번호로 이전해드리겠습니다.",
    },
    {
      title: "신분증 및 개인정보를 잘못입력했어요",
      body: "잘못된 정보를 입력시 모든 정보는 초기화될수 있습니다.  정확한 정보 입력 부탁드립니다.",
    },
    {
      title: "이메일 인증이 오지 않아요.",
      body: "스팸 메일함도 다시 한번 확인해주시기 바라며, 그럼에도 이메일을 못받으신 경우 고객센터 또는 CS챗 상담을 통해 연락 주시기 바랍니다.",
    },
    {
      title: "환전예약하고 싶은데, 어떻게 진행해야 하나요?",
      body: "(내국인) 메인 화면의 모바일 환전예약 메뉴를 통해 예약 가능합니다.",
    },
    {
      title: "환전예약 절차가 궁금합니다.",
      body: "지점선택>날짜선택>외화금액선택>원화금액입금 순으로 진행해 주시면, 최대한 빠르고 안전한 거래를 위해 접수 순서대로 입금확인을 진행 하며, 평균 업무 시간 기준 1시간 이내 예약 확정이 됩니다. 예약확정 이후 선택하신 수령일에 선택하신 키오스크를 방문하시면 바코드 스캔으로 수령가능합니다.",
    },
    {
      title:
        "모바일 환전예약 후 키오스크에서 어떻게 외화를 수령할 수 있나요? (내국인)",
      body: "키오스크상에서 모바일예약 수령 메뉴를 클릭 후 회원 바코드 스캔시 즉시 수령 가능합니다.",
    },
    {
      title: "환전 예약을 취소하고 싶어요. (내국인)",
      body: "입금전 대기 상태라면 직접 모바일앱 상에서 취소가 가능합니다. 이미 입금 완료 상태라면 환전 취소 수수료가 1% 발생됩니다",
    },
    {
      title: "환전예약하고 싶은데, 어디에서 지점을 확인 할수 있나요? (내국인)",
      body: "머니플렉스 모바일App 을 실행하신 후, 메인 화면의 환전예약 메뉴와 지점찾기 메뉴에서 현재 이용가능한 지점 리스트를 확인 하실 수 있습니다.  지점 선택 하신 후 이용정보와 지도보기를 통해 자세한 위치를 확인하실 수 있습니다.",
    },
    {
      title: "수령 날짜를 변경하고 싶어요",
      body: "현재 시스템상에서 수령일자 변경은 불가합니다. 수령일자 변경이 필요하실경 고객센터로 문의부탁드립니다.",
    },

    {
      title: "환전예약하고 싶은데, 어디에서 지점을 확인 할수 있나요? (내국인)",
      body: "머니플렉스 모바일App 을 실행하신 후, 메인 화면의 환전예약 메뉴와 지점찾기 메뉴에서 현재 이용가능한 지점 리스트를 확인 하실 수 있습니다.  지점 선택 하신 후 이용정보와 지도보기를 통해 자세한 위치를 확인하실 수 있습니다.",
    },
    {
      title: "수령 날짜를 변경하고 싶어요",
      body: "현재 시스템상에서 수령일자 변경은 불가합니다. 수령일자 변경이 필요하실경 고객센터로 문의부탁드립니다.",
    },

    {
      title: "두 건을 동시에 환전 신청할수 있나요?",
      body: "이중신청은 되지 않습니다. 기존 예약건을 수령 후 새로 신청해주세요",
    },
    {
      title: "포인트로 어떤것을 할수 있나요?",
      body: "머니플렉스 포인트샵 메뉴에서 600여개 브랜드의 모바일 상품권을 구입하실 수 있습니다. 향후 포인트로 다양한 제휴처 결제 및 계좌이체 서비스를 지원할 예정입니다.",
    },
    {
      title: "모바일 상품권은 어떻게 구매하나요?",
      body: "머니플렉스 앱-포인트샵-카테고리 및 포인트샵 선택-하단에 상품구매를 클릭합니다. 포인트로만 구매가능하고 차액 추가 결제는 지원하지 않습니다.",
    },
    {
      title: "탈퇴는어떻게 하나요?",
      body: "앱 하단의 마이페이지 메뉴에서 이메일 정보를 터치하시면 회원탈퇴 메뉴에서 진행하실 수 있습니다.",
    },
  ],
  EN: [
    {
      title: "Where can I download the MoneyPlex app?",
      body: "For iOS, search '머니플렉스' in the App Store, and for AOS (Android), search 'moneyplex' in the Play Store.",
    },
    {
      title: "What process do I need to follow to sign up for the app?",
      body: "The MoneyPlex mobile app is available for domestic users (Koreans) through email verification and password verification. For foreigners, signing up is completed with just email verification.",
    },
    {
      title: "I lost my login password.",
      body: "After launching the MoneyPlex mobile app, press the login button on the main screen, then you can find it by clicking 'Find Password'.",
    },
    {
      title: "How do I change my login password?",
      body: "Touch your email information in the My Page menu at the bottom of the app, and you can change your email password from there.",
    },
    {
      title: "How do I change my security password?",
      body: "Touch your email information in the My Page menu at the bottom of the app, and you can change your security password from there.",
    },
    {
      title: "How do I change my phone number and name?",
      body: "You need to withdraw and re-register. If you re-register, your existing points will be transferred to the new number.",
    },
    {
      title: "I entered my ID and personal information incorrectly.",
      body: "If incorrect information is entered, all information may be reset. Please enter accurate information.",
    },
    {
      title: "I didn't receive the email verification.",
      body: "Please check your spam folder again, and if you still haven't received the email, please contact customer service or use the CS chat.",
    },
    {
      title:
        "I want to make a currency exchange reservation, how do I proceed?",
      body: "(Domestic) You can make a reservation through the mobile exchange reservation menu on the main screen.",
    },
    {
      title: "What is the process for currency exchange reservation?",
      body: "Select branch > select date > select foreign currency amount > deposit KRW. We will confirm deposits in order to ensure a fast and secure transaction, and reservations are typically confirmed within an hour based on average business hours. After confirmation, visit the selected kiosk on the chosen pickup date to receive your money by scanning the barcode.",
    },
    {
      title:
        "How can I receive foreign currency at the kiosk after making a mobile reservation? (Domestic)",
      body: "Click the mobile reservation receipt menu at the kiosk and scan your member barcode to receive it instantly.",
    },
    {
      title: "I want to cancel my currency exchange reservation. (Domestic)",
      body: "If you are in a waiting state before making a deposit, you can cancel directly in the mobile app. If you have already completed the deposit, a 1% cancellation fee will be charged.",
    },
    {
      title:
        "Where can I check available branches for currency exchange reservations? (Domestic)",
      body: "After launching the MoneyPlex mobile app, you can check the list of currently available branches in the currency exchange reservation and branch search menus on the main screen. After selecting a branch, you can check the details and map view for precise location.",
    },
    {
      title: "I want to change the pickup date.",
      body: "Currently, changing the pickup date is not possible in the system. If you need to change the pickup date, please contact customer service.",
    },
    {
      title: "Can I apply for two exchanges at the same time?",
      body: "Dual applications are not allowed. Please receive your existing reservation before applying again.",
    },
    {
      title: "What can I do with points?",
      body: "In the MoneyPlex point shop menu, you can purchase mobile gift certificates from over 600 brands. In the future, we plan to support various payment and transfer services with points.",
    },
    {
      title: "How do I purchase a mobile gift certificate?",
      body: "In the MoneyPlex app, go to Point Shop > select category and Point Shop > click 'Buy Product' at the bottom. Purchases can only be made with points, and additional payments for the difference are not supported.",
    },
    {
      title: "How do I withdraw my membership?",
      body: "Touch your email information in the My Page menu at the bottom of the app, and you can proceed with the membership withdrawal menu.",
    },
  ],
  CN: [
    {
      title: "MoneyPlex应用程序在哪里下载？",
      body: "对于iOS，请在App Store中搜索'머니플렉스'，对于AOS（Android），请在Play Store中搜索'moneyplex'。",
    },
    {
      title: "注册应用程序需要进行哪些过程？",
      body: "MoneyPlex移动应用程序对于国内用户（韩国人）可通过电子邮件验证和密码验证进行使用。对于外国人，注册仅需进行电子邮件验证即可完成。",
    },
    {
      title: "我忘记了登录密码。",
      body: "启动MoneyPlex移动应用程序后，按下主屏幕上的登录按钮，然后可以通过点击'找回密码'来查找。",
    },
    {
      title: "如何更改登录密码？",
      body: "在应用程序底部的我的页面菜单中触摸您的电子邮件信息，您可以在电子邮件密码更改菜单中进行更改。",
    },
    {
      title: "如何更改安全密码？",
      body: "在应用程序底部的我的页面菜单中触摸您的电子邮件信息，您可以在安全密码更改菜单中进行更改。",
    },
    {
      title: "如何更改手机号码和姓名？",
      body: "您需要退会并重新注册。如果您重新注册，将把您现有的积分转移到新号码。",
    },
    {
      title: "我错误输入了身份证和个人信息。",
      body: "如果输入错误的信息，所有信息可能会被重置。请确保输入准确的信息。",
    },
    {
      title: "我没有收到电子邮件验证。",
      body: "请再次检查垃圾邮件文件夹，如果仍然没有收到电子邮件，请通过客户服务或CS聊天进行联系。",
    },
    {
      title: "我想预约外汇，该如何进行？",
      body: "(国内用户) 您可以通过主屏幕的移动外汇预约菜单进行预约。",
    },
    {
      title: "外汇预约的流程是什么？",
      body: "选择分店 > 选择日期 > 选择外币金额 > 存入韩元。为了确保交易尽可能快速安全，我们将按照申请顺序进行存款确认，平均工作时间为1小时内确认预约。在预约确认后，您可以在选择的领取日期访问所选的自助服务机，通过扫描条形码领取。",
    },
    {
      title: "在移动预约后，如何在自助服务机领取外币？（国内用户）",
      body: "在自助服务机上点击移动预约领取菜单，扫描会员条形码即可立即领取。",
    },
    {
      title: "我想取消外汇预约。（国内用户）",
      body: "如果您在存款之前处于待处理状态，您可以直接在移动应用程序中取消。如果您已经完成存款，则会产生1%的取消手续费。",
    },
    {
      title: "我想预约外汇，在哪里可以查看分店？（国内用户）",
      body: "启动MoneyPlex移动应用程序后，您可以在主屏幕的外汇预约菜单和分店查找菜单中查看当前可用的分店列表。选择分店后，可以通过查看使用信息和地图来确认详细位置。",
    },
    {
      title: "我想更改领取日期。",
      body: "目前系统不支持更改领取日期。如果需要更改领取日期，请联系客户服务。",
    },
    {
      title: "我可以同时申请两笔外汇吗？",
      body: "不允许重复申请。请在领取现有预约后再申请。",
    },
    {
      title: "我可以用积分做什么？",
      body: "在MoneyPlex积分商店菜单中，您可以购买600多个品牌的移动礼品卡。未来我们计划支持使用积分进行多种支付和转账服务。",
    },
    {
      title: "如何购买移动礼品卡？",
      body: "在MoneyPlex应用程序中 - 积分商店 - 选择类别和积分商店 - 点击底部的商品购买。仅支持使用积分购买，不支持差额的额外支付。",
    },
    {
      title: "如何退出会员？",
      body: "在应用程序底部的我的页面菜单中触摸您的电子邮件信息，您可以在会员退出菜单中进行操作。",
    },
  ],
  CT: [
    {
      title: "MoneyPlex應用程式在哪裡下載？",
      body: "對於iOS，請在App Store中搜索'머니플렉스'，對於AOS（Android），請在Play Store中搜索'moneyplex'。",
    },
    {
      title: "註冊應用程式需要進行哪些過程？",
      body: "MoneyPlex移動應用程式對於國內用戶（韓國人）可透過電子郵件驗證和密碼驗證進行使用。對於外國人，註冊僅需進行電子郵件驗證即可完成。",
    },
    {
      title: "我忘記了登錄密碼。",
      body: "啟動MoneyPlex移動應用程式後，按下主屏幕上的登錄按鈕，然後可以通過點擊'找回密碼'來查找。",
    },
    {
      title: "如何更改登錄密碼？",
      body: "在應用程式底部的我的頁面菜單中觸摸您的電子郵件信息，您可以在電子郵件密碼更改菜單中進行更改。",
    },
    {
      title: "如何更改安全密碼？",
      body: "在應用程式底部的我的頁面菜單中觸摸您的電子郵件信息，您可以在安全密碼更改菜單中進行更改。",
    },
    {
      title: "如何更改手機號碼和姓名？",
      body: "您需要退會並重新註冊。如果您重新註冊，將把您現有的積分轉移到新號碼。",
    },
    {
      title: "我錯誤輸入了身份證和個人信息。",
      body: "如果輸入錯誤的信息，所有信息可能會被重置。請確保輸入準確的信息。",
    },
    {
      title: "我沒有收到電子郵件驗證。",
      body: "請再次檢查垃圾郵件文件夾，如果仍然沒有收到電子郵件，請通過客戶服務或CS聊天進行聯繫。",
    },
    {
      title: "我想預約外匯，該如何進行？",
      body: "(國內用戶) 您可以通過主屏幕的移動外匯預約菜單進行預約。",
    },
    {
      title: "外匯預約的流程是什麼？",
      body: "選擇分店 > 選擇日期 > 選擇外幣金額 > 存入韓元。為了確保交易盡可能快速安全，我們將按照申請順序進行存款確認，平均工作時間為1小時內確認預約。在預約確認後，您可以在選擇的領取日期訪問所選的自助服務機，通過掃描條形碼領取。",
    },
    {
      title: "在移動預約後，如何在自助服務機領取外幣？（國內用戶）",
      body: "在自助服務機上點擊移動預約領取菜單，掃描會員條形碼即可立即領取。",
    },
    {
      title: "我想取消外匯預約。（國內用戶）",
      body: "如果您在存款之前處於待處理狀態，您可以直接在移動應用程式中取消。如果您已經完成存款，則會產生1%的取消手續費。",
    },
    {
      title: "我想預約外匯，在哪裡可以查看分店？（國內用戶）",
      body: "啟動MoneyPlex移動應用程式後，您可以在主屏幕的外匯預約菜單和分店查找菜單中查看當前可用的分店列表。選擇分店後，可以通過查看使用信息和地圖來確認詳細位置。",
    },
    {
      title: "我想更改領取日期。",
      body: "目前系統不支持更改領取日期。如果需要更改領取日期，請聯繫客戶服務。",
    },
    {
      title: "我可以同時申請兩筆外匯嗎？",
      body: "不允許重複申請。請在領取現有預約後再申請。",
    },
    {
      title: "我可以用積分做什麼？",
      body: "在MoneyPlex積分商店菜單中，您可以購買600多個品牌的移動禮品卡。未來我們計劃支持使用積分進行多種支付和轉賬服務。",
    },
    {
      title: "如何購買移動禮品卡？",
      body: "在MoneyPlex應用程式中 - 積分商店 - 選擇類別和積分商店 - 點擊底部的商品購買。僅支持使用積分購買，不支持差額的額外支付。",
    },
    {
      title: "如何退出會員？",
      body: "在應用程式底部的我的頁面菜單中觸摸您的電子郵件信息，您可以在會員退出菜單中進行操作。",
    },
  ],
  JP: [
    {
      title: "MoneyPlexアプリはどこでダウンロードできますか？",
      body: "iOSはApp Storeで「머니플렉스」を検索し、AOS（Android）はPlay Storeで「moneyplex」を検索してください。",
    },
    {
      title: "アプリに登録するにはどのような手続きを進める必要がありますか？",
      body: "MoneyPlexモバイルアプリは、国内ユーザー（韓国人）の場合、メール認証とパスワード認証を通じて利用可能です。外国人の場合、メール認証のみで登録が完了します。",
    },
    {
      title: "ログインパスワードを失いました。",
      body: "MoneyPlexモバイルアプリを起動し、メイン画面のログインボタンを押した後、表示された画面の「パスワードを探す」から確認できます。",
    },
    {
      title: "ログインパスワードはどうやって変更しますか？",
      body: "アプリの下部にあるマイページメニューでメール情報をタッチすると、メールパスワード変更メニューで変更できます。",
    },
    {
      title: "セキュリティパスワードはどうやって変更しますか？",
      body: "アプリの下部にあるマイページメニューでメール情報をタッチすると、セキュリティパスワード変更メニューで変更できます。",
    },
    {
      title: "電話番号と名前はどうやって変更しますか？",
      body: "退会後に再登録が必要です。再登録すると、以前のポイントを新しい番号に移行します。",
    },
    {
      title: "身分証明書と個人情報を誤って入力しました。",
      body: "誤った情報を入力すると、すべての情報が初期化される可能性があります。正確な情報を入力してください。",
    },
    {
      title: "メール認証が来ません。",
      body: "迷惑メールフォルダも再度確認してください。それでもメールが届かない場合は、カスタマーサービスまたはCSチャットでお問い合わせください。",
    },
    {
      title: "為替予約をしたいのですが、どうすればいいですか？",
      body: "(国内) メイン画面のモバイル為替予約メニューから予約できます。",
    },
    {
      title: "為替予約の手順が知りたいです。",
      body: "支店選択 > 日付選択 > 外貨金額選択 > 韓国ウォン金額入金の順で進めていただければ、できるだけ早く安全な取引を確保するために入金確認を行い、平均業務時間に基づいて1時間以内に予約が確定します。予約確定後に選択した受取日に選択したキオスクを訪れ、バーコードをスキャンして受け取ることができます。",
    },
    {
      title:
        "モバイル為替予約後にキオスクで外貨を受け取るにはどうすればいいですか？ (国内)",
      body: "キオスクでモバイル予約受取メニューをクリックし、会員バーコードをスキャンすることで即座に受け取ることができます。",
    },
    {
      title: "為替予約をキャンセルしたいです。 (国内)",
      body: "入金前の待機状態であれば、モバイルアプリから直接キャンセルできます。既に入金が完了している場合は、為替キャンセル手数料が1%かかります。",
    },
    {
      title: "為替予約をしたいのですが、どこで支店を確認できますか？ (国内)",
      body: "MoneyPlexモバイルアプリを起動し、メイン画面の為替予約メニューと支店検索メニューから現在利用可能な支店リストを確認できます。支店を選択した後、利用情報と地図ビューで詳細な位置を確認できます。",
    },
    {
      title: "受取日を変更したいです。",
      body: "現在のシステムでは受取日を変更することはできません。受取日変更が必要な場合は、カスタマーサービスにお問い合わせください。",
    },
    {
      title: "同時に2件の為替申請ができますか？",
      body: "重複申請はできません。既存の予約を受け取った後に新たに申請してください。",
    },
    {
      title: "ポイントで何ができますか？",
      body: "MoneyPlexポイントショップメニューで600以上のブランドのモバイルギフト券を購入できます。今後はポイントを使ったさまざまな提携先での決済および口座振替サービスを提供する予定です。",
    },
    {
      title: "モバイルギフト券はどうやって購入しますか？",
      body: "MoneyPlexアプリ - ポイントショップ - カテゴリーおよびポイントショップ選択 - 下部にある商品購入をクリックします。ポイントでのみ購入可能で、差額の追加支払いはサポートしていません。",
    },
    {
      title: "退会はどうしますか？",
      body: "アプリの下部にあるマイページメニューでメール情報をタッチすると、会員退会メニューから進めることができます。",
    },
  ],
  TH: [
    {
      title: "แอป MoneyPlex ดาวน์โหลดได้ที่ไหน?",
      body: "สำหรับ iOS ให้ค้นหา '머니플렉스' ใน App Store และสำหรับ AOS (Android) ให้ค้นหา 'moneyplex' ใน Play Store.",
    },
    {
      title: "จะต้องดำเนินการอย่างไรในการสมัครสมาชิกแอป?",
      body: "แอป MoneyPlex สำหรับผู้ใช้ในประเทศ (ชาวเกาหลี) สามารถใช้งานได้ผ่านการยืนยันทางอีเมลและการยืนยันรหัสผ่าน สำหรับชาวต่างชาติ การสมัครสมาชิกจะเสร็จสมบูรณ์ด้วยการยืนยันทางอีเมลเพียงอย่างเดียว.",
    },
    {
      title: "ฉันลืมรหัสผ่านในการล็อกอิน.",
      body: "หลังจากเปิดแอป MoneyPlex ให้กดปุ่มล็อกอินบนหน้าจอหลัก จากนั้นคุณสามารถค้นหารหัสผ่านได้จากหน้าจอที่ปรากฏขึ้นโดยคลิกที่ 'ค้นหารหัสผ่าน'.",
    },
    {
      title: "จะเปลี่ยนรหัสผ่านในการล็อกอินได้อย่างไร?",
      body: "แตะข้อมูลอีเมลของคุณในเมนูหน้า My Page ที่ด้านล่างของแอป จากนั้นคุณสามารถเปลี่ยนรหัสผ่านอีเมลได้จากที่นั่น.",
    },
    {
      title: "จะเปลี่ยนรหัสผ่านความปลอดภัยได้อย่างไร?",
      body: "แตะข้อมูลอีเมลของคุณในเมนูหน้า My Page ที่ด้านล่างของแอป จากนั้นคุณสามารถเปลี่ยนรหัสผ่านความปลอดภัยได้จากที่นั่น.",
    },
    {
      title: "จะเปลี่ยนหมายเลขโทรศัพท์และชื่อได้อย่างไร?",
      body: "คุณต้องถอนการสมัครและลงทะเบียนใหม่ หากคุณลงทะเบียนใหม่ คุณจะสามารถย้ายคะแนนที่มีอยู่ไปยังหมายเลขใหม่.",
    },
    {
      title: "ฉันกรอกข้อมูลประจำตัวและข้อมูลส่วนตัวผิดพลาด.",
      body: "หากกรอกข้อมูลผิดพลาด ข้อมูลทั้งหมดอาจถูกรีเซ็ต โปรดกรอกข้อมูลที่ถูกต้อง.",
    },
    {
      title: "ไม่ได้รับการยืนยันทางอีเมล.",
      body: "โปรดตรวจสอบโฟลเดอร์อีเมลขยะอีกครั้ง และหากยังไม่ได้รับอีเมล กรุณาติดต่อบริการลูกค้าหรือใช้แชท CS.",
    },
    {
      title: "ฉันต้องการจองการแลกเปลี่ยนเงินตรา จะต้องดำเนินการอย่างไร?",
      body: "(ชาวในประเทศ) คุณสามารถทำการจองได้ผ่านเมนูจองแลกเปลี่ยนเงินในหน้าจอหลัก.",
    },
    {
      title: "ขั้นตอนการจองแลกเปลี่ยนเงินคืออะไร?",
      body: "เลือกสาขา > เลือกวันที่ > เลือกจำนวนเงินต่างประเทศ > ฝากเงินวอนเกาหลี จากนั้นเราจะยืนยันการฝากเงินเพื่อให้แน่ใจว่าการทำธุรกรรมรวดเร็วและปลอดภัย โดยจะมีการยืนยันการจองภายใน 1 ชั่วโมงตามเวลาทำการเฉลี่ย หลังจากการยืนยัน คุณสามารถไปที่คีออสก์ที่เลือกในวันรับที่เลือกและรับเงินโดยการสแกนบาร์โค้ด.",
    },
    {
      title:
        "ฉันจะรับเงินต่างประเทศที่คีออสก์หลังจากจองมือถือได้อย่างไร? (ชาวในประเทศ)",
      body: "คลิกเมนูรับจองมือถือที่คีออสก์ จากนั้นสแกนบาร์โค้ดสมาชิกของคุณเพื่อรับเงินทันที.",
    },
    {
      title: "ฉันต้องการยกเลิกการจองแลกเปลี่ยนเงิน (ชาวในประเทศ)",
      body: "หากคุณอยู่ในสถานะรอคอยก่อนการฝากเงิน คุณสามารถยกเลิกได้โดยตรงในแอปมือถือ หากคุณได้ทำการฝากเงินแล้ว จะมีค่าธรรมเนียมการยกเลิก 1%.",
    },
    {
      title:
        "ฉันสามารถตรวจสอบสาขาที่ใช้สำหรับการจองแลกเปลี่ยนเงินได้ที่ไหน? (ชาวในประเทศ)",
      body: "หลังจากเปิดแอป MoneyPlex คุณสามารถตรวจสอบรายชื่อสาขาที่ใช้งานได้ในเมนูจองแลกเปลี่ยนเงินและค้นหาสาขาในหน้าจอหลัก หลังจากเลือกสาขาแล้ว คุณสามารถตรวจสอบข้อมูลการใช้งานและดูแผนที่เพื่อระบุที่ตั้งอย่างละเอียด.",
    },
    {
      title: "ฉันต้องการเปลี่ยนวันที่รับเงิน.",
      body: "ในปัจจุบัน การเปลี่ยนวันที่รับเงินในระบบไม่สามารถทำได้ หากต้องการเปลี่ยนวันที่รับเงิน กรุณาติดต่อบริการลูกค้า.",
    },
    {
      title: "ฉันสามารถสมัครแลกเปลี่ยนเงินสองรายการในเวลาเดียวกันได้ไหม?",
      body: "ไม่สามารถสมัครซ้ำได้ กรุณารับการจองเดิมก่อนที่จะสมัครใหม่.",
    },
    {
      title: "ฉันสามารถทำอะไรกับคะแนนได้บ้าง?",
      body: "ในเมนูร้านคะแนนของ MoneyPlex คุณสามารถซื้อบัตรของขวัญมือถือจากมากกว่า 600 แบรนด์ ในอนาคตเราจะสนับสนุนการชำระเงินและบริการโอนเงินหลายอย่างด้วยคะแนน.",
    },
    {
      title: "จะซื้อบัตรของขวัญมือถือได้อย่างไร?",
      body: "ในแอป MoneyPlex - ร้านคะแนน - เลือกหมวดหมู่และร้านคะแนน - คลิก 'ซื้อผลิตภัณฑ์' ที่ด้านล่าง สามารถซื้อได้เฉพาะด้วยคะแนนเท่านั้น และไม่รองรับการชำระเงินเพิ่มเติมสำหรับความแตกต่าง.",
    },
    {
      title: "จะถอนการสมัครสมาชิกได้อย่างไร?",
      body: "แตะข้อมูลอีเมลของคุณในเมนู My Page ที่ด้านล่างของแอป จากนั้นคุณสามารถดำเนินการในเมนูถอนการสมัครสมาชิก.",
    },
  ],
};

const FaqETC = {
  KR: [
    {
      title: "환율 수수료 있나요?",
      body: "외화 구매시 환전 수수료는 없고 외화 매도 시 수수료에서 ~% 우대해드립니다.",
    },
    {
      title: "업데이트를 했는데, 로그아웃되었어요. ",
      body: "새롭게 업데이트 된 머니플렉스 서비스에서 신규 가입을 진행해주세요. 이메일 가입과 함께 패스인증을 진행하시면, 기존 앱에서 이용하셨던 데이터 모두 그대로 이용 하실 수 있습니다.",
    },
    {
      title: "가입 불만 사항",
      body: "고객님의 소중한 정보 보호를 하기 위해, 이번 업데이트에서 로그인 방식이 변경 되었습니다.  이메일 인증과 패스인증을 진행해주시면, 기존 앱에서 이용하셨던 소중한 고객님의 데이터 모두 그대로 이용 하실 수 있습니다.",
    },
    {
      title: "기존회원인데 가입이 안돼요/ 가입절차 에러가 떠요.",
      body: "고객님의 성함, 생년월일, 핸드폰 기종, 번호까지 확인 부탁드립니다. (원인 파악 후 다시 안내)",
    },
    {
      title: "유심 관련 문의사항",
      body: "고객님, 해당 유심 구매는 제휴사인 유심사에서 운영관리되고 있습니다. 머니플렉스 2.0 앱의 유심/이심 메뉴안에서 맨 아래 '문의하기' 메뉴로 문의 해주시거나, 유심사로 직접 문의하여주시기 바랍니다.",
    },
    {
      title: "가상화폐를 취급 하나요? ",
      body: "테더,usdt등 모든 가상화폐 코인은 취급 하지 않습니다",
    },
    {
      title: "판교에서 동전 환전가능한지?",
      body: "판교사무실에서 동전은 환전 불가능하고 머니플렉스 어플에서 고객님과 제일 가까운 지점 확인 할 수 있습니다.",
    },
    {
      title: "동전 사고싶어요.",
      body: "미취급동전은 따로 구매가 어렵고 취급동전만 구입 가능하십니다.(취급동전, 미취급동전 같이 안내 필요)",
    },
    {
      title: "해외 송금 가능한가요?",
      body: "해외 송금은 하지 않습니다.",
    },

    {
      title: "기부하기는 어디로 되나요?",
      body: "한국사회 투자 비영리 기관으로 전부 기부 됩니다.",
    },
  ],
  EN: [
    {
      title: "Is there a currency exchange fee?",
      body: "There are no fees when purchasing foreign currency, and we provide a discount of ~% when selling foreign currency.",
    },
    {
      title: "I got logged out after the update.",
      body: "Please proceed with the new registration in the updated MoneyPlex service. If you sign up with your email and complete the password authentication, you can access all the data you used in the previous app.",
    },
    {
      title: "Registration complaint",
      body: "To protect your valuable information, the login method has changed with this update. If you complete email verification and password authentication, you will be able to access all your valuable data from the previous app.",
    },
    {
      title:
        "I am an existing member but cannot register / I see a registration procedure error.",
      body: "Please check your name, date of birth, mobile model, and number. (We will guide you after identifying the cause.)",
    },
    {
      title: "Questions regarding SIM cards",
      body: "Dear customer, the purchase of this SIM card is managed by our partner, SIMSA. Please contact us through the 'Contact Us' menu at the bottom of the SIM/eSIM menu in the MoneyPlex 2.0 app, or directly contact SIMSA.",
    },
    {
      title: "Do you deal with cryptocurrencies?",
      body: "We do not deal with any cryptocurrencies, including Tether and USDT.",
    },
    {
      title: "Can I exchange coins at Pangyo?",
      body: "Coins cannot be exchanged at the Pangyo office, but you can check the nearest location through the MoneyPlex app.",
    },
    {
      title: "I want to buy coins.",
      body: "It is difficult to purchase non-dealt coins separately; you can only buy dealt coins. (Need to clarify both dealt and non-dealt coins.)",
    },
    {
      title: "Can I send money overseas?",
      body: "We do not offer overseas remittance services.",
    },
  ],
  CN: [
    {
      title: "有汇率手续费吗？",
      body: "购买外币时没有兑换手续费，出售外币时提供 ~% 的优惠。",
    },
    {
      title: "更新后我被登出了。",
      body: "请在更新后的MoneyPlex服务中进行新注册。如果您使用电子邮件注册并完成密码认证，您可以继续使用以前应用程序中的所有数据。",
    },
    {
      title: "注册投诉",
      body: "为了保护您的宝贵信息，此次更新更改了登录方式。完成电子邮件验证和密码认证后，您可以使用以前应用程序中的所有宝贵数据。",
    },
    {
      title: "我是一名现有会员但无法注册/注册流程出现错误。",
      body: "请检查您的姓名、出生日期、手机型号和号码。 （确认原因后将再次指导。）",
    },
    {
      title: "关于SIM卡的问题",
      body: "亲爱的客户，此SIM卡的购买由我们的合作伙伴SIMSA进行管理。请通过MoneyPlex 2.0应用程序SIM/eSIM菜单底部的'联系我'菜单与我们联系，或直接联系SIMSA。",
    },
    {
      title: "你们有处理加密货币吗？",
      body: "我们不处理任何加密货币，包括泰达币和USDT。",
    },
    {
      title: "可以在板桥兑换硬币吗？",
      body: "板桥办公室无法兑换硬币，但您可以通过MoneyPlex应用检查最近的地点。",
    },
    {
      title: "我想买硬币。",
      body: "难以单独购买未处理的硬币；您只能购买已处理的硬币。 （需要同时说明已处理和未处理的硬币。）",
    },
    {
      title: "我可以汇款到海外吗？",
      body: "我们不提供海外汇款服务。",
    },
  ],
  CT: [
    {
      title: "有匯率手續費嗎？",
      body: "購買外幣時沒有兌換手續費，出售外幣時提供 ~% 的優惠。",
    },
    {
      title: "更新後我被登出了。",
      body: "請在更新後的MoneyPlex服務中進行新註冊。如果您使用電子郵件註冊並完成密碼認證，您可以繼續使用以前應用程序中的所有數據。",
    },
    {
      title: "註冊投訴",
      body: "為了保護您的寶貴信息，此次更新更改了登錄方式。完成電子郵件驗證和密碼認證後，您可以使用以前應用程序中的所有寶貴數據。",
    },
    {
      title: "我是一名現有會員但無法註冊/註冊流程出現錯誤。",
      body: "請檢查您的姓名、出生日期、手機型號和號碼。（確認原因後將再次指導。）",
    },
    {
      title: "關於SIM卡的問題",
      body: "親愛的客戶，此SIM卡的購買由我們的合作夥伴SIMSA進行管理。請通過MoneyPlex 2.0應用程序SIM/eSIM菜單底部的'聯繫我'菜單與我們聯繫，或直接聯繫SIMSA。",
    },
    {
      title: "你們有處理加密貨幣嗎？",
      body: "我們不處理任何加密貨幣，包括泰達幣和USDT。",
    },
    {
      title: "可以在板橋兌換硬幣嗎？",
      body: "板橋辦公室無法兌換硬幣，但您可以通過MoneyPlex應用檢查最近的地點。",
    },
    {
      title: "我想買硬幣。",
      body: "難以單獨購買未處理的硬幣；您只能購買已處理的硬幣。（需要同時說明已處理和未處理的硬幣。）",
    },
    {
      title: "我可以匯款到海外嗎？",
      body: "我們不提供海外匯款服務。",
    },
  ],
  JP: [
    {
      title: "為替手数料はありますか？",
      body: "外貨購入時には手数料はなく、外貨売却時には～%の優遇があります。",
    },
    {
      title: "アップデート後にログアウトされました。",
      body: "新しくアップデートされたMoneyPlexサービスで新規登録を行ってください。メール登録とパスワード認証を行うことで、以前のアプリで使用していたデータをそのまま利用できます。",
    },
    {
      title: "登録に関する不満",
      body: "お客様の貴重な情報を保護するために、今回のアップデートでログイン方式が変更されました。メール認証とパスワード認証を行うことで、以前のアプリで使用していた貴重なデータをそのまま利用できます。",
    },
    {
      title: "既存会員ですが、登録できません/登録手続きエラーが表示されます。",
      body: "お客様のお名前、生年月日、携帯電話の機種、番号を確認してください。（原因確認後、再度案内いたします。）",
    },
    {
      title: "SIMカードに関する問い合わせ",
      body: "お客様、このSIMカードの購入は提携先のSIMSAが管理しています。MoneyPlex 2.0アプリのSIM/eSIMメニューの下部にある「お問い合わせ」メニューからご連絡いただくか、直接SIMSAにお問い合わせください。",
    },
    {
      title: "仮想通貨を取り扱っていますか？",
      body: "テザーやUSDTなどのすべての仮想通貨コインは取り扱っていません。",
    },
    {
      title: "板橋でコインを交換できますか？",
      body: "板橋オフィスではコインの交換はできませんが、MoneyPlexアプリで最寄りの店舗を確認できます。",
    },
    {
      title: "コインが欲しいです。",
      body: "取り扱っていないコインは別途購入が難しく、取り扱いのあるコインのみ購入可能です。（取り扱いコインと未取り扱いコインについての案内が必要です。）",
    },
    {
      title: "海外送金はできますか？",
      body: "海外送金は行っていません。",
    },
  ],
  TH: [
    {
      title: "มีค่าธรรมเนียมการแลกเปลี่ยนเงินหรือไม่?",
      body: "เมื่อซื้อเงินต่างประเทศไม่มีค่าธรรมเนียมการแลกเปลี่ยน และเมื่อขายเงินต่างประเทศจะมีการลดราคา ~%。",
    },
    {
      title: "ฉันถูกออกจากระบบหลังจากอัปเดตแล้ว。",
      body: "โปรดทำการลงทะเบียนใหม่ในบริการ MoneyPlex ที่อัปเดต หากคุณลงทะเบียนด้วยอีเมลและทำการยืนยันรหัสผ่าน คุณจะสามารถเข้าถึงข้อมูลทั้งหมดที่คุณใช้ในแอปก่อนหน้านี้ได้。",
    },
    {
      title: "ข้อร้องเรียนเกี่ยวกับการลงทะเบียน",
      body: "เพื่อปกป้องข้อมูลที่มีค่าของคุณ วิธีการเข้าสู่ระบบได้มีการเปลี่ยนแปลงในการอัปเดตครั้งนี้ หากคุณทำการยืนยันอีเมลและยืนยันรหัสผ่าน คุณจะสามารถเข้าถึงข้อมูลลูกค้าที่มีค่าจากแอปก่อนหน้าได้ทั้งหมด。",
    },
    {
      title: "เป็นสมาชิกอยู่แล้วแต่ลงทะเบียนไม่ได้/มีข้อผิดพลาดในการลงทะเบียน",
      body: "กรุณาตรวจสอบชื่อ, วันเกิด, รุ่นโทรศัพท์มือถือ และหมายเลขของคุณ (เราจะแนะนำอีกครั้งหลังจากตรวจสอบสาเหตุ)。",
    },
    {
      title: "ข้อสงสัยเกี่ยวกับซิมการ์ด",
      body: "เรียนลูกค้า การซื้อซิมการ์ดนี้จะถูกจัดการโดยพันธมิตร SIMSA โปรดติดต่อเราผ่านเมนู 'ติดต่อเรา' ที่ด้านล่างของเมนูซิม/อีซิมในแอป MoneyPlex 2.0 หรือสอบถาม SIMSA โดยตรง。",
    },
    {
      title: "คุณจัดการกับสกุลเงินดิจิทัลหรือไม่?",
      body: "เราไม่จัดการกับสกุลเงินดิจิทัลใด ๆ รวมถึง Tether และ USDT。",
    },
    {
      title: "สามารถแลกเหรียญที่ปางเคียวได้หรือไม่?",
      body: "ไม่สามารถแลกเหรียญที่สำนักงานปางเคียวได้ แต่คุณสามารถตรวจสอบสถานที่ที่ใกล้ที่สุดได้ผ่านแอป MoneyPlex。",
    },
    {
      title: "ฉันต้องการซื้อเหรียญ",
      body: "การซื้อเหรียญที่ไม่ได้รับการจัดการแยกเป็นเรื่องยาก และคุณสามารถซื้อเหรียญที่ได้รับการจัดการเท่านั้น (ต้องการการชี้แจงทั้งเหรียญที่ได้รับการจัดการและไม่ได้รับการจัดการ)。",
    },
    {
      title: "ฉันสามารถส่งเงินไปต่างประเทศได้หรือไม่?",
      body: "เราไม่ให้บริการการโอนเงินไปต่างประเทศ。",
    },
  ],
};

const FaqKiosk = {
  KR: [
    {
      title: "키오스크에서 환전하는 방법이 궁금해요.",
      body: "외화판매,외화구매 두가지 방법이 있습니다.외화 판매: 키오스크가서 실시간으로 판매 (외화투입->원화수령 키스크에서는 내외국인 모두가능) 외화 구매: 머니플렉스 앱에서 환전예약을 미리 한후 키오스크 방문하여 구매( 원화입금->외화수령) (내국인만 해당)",
    },
    {
      title: "외화를 투입하면 어떻게 받을 수 있나요?",
      body: "키오스크에서 환전한 한국 원화(KRW)는 즉시 현금 수령가능하며, 머니플렉스앱 및 KB국민지갑으로 충전 또는 기부하기가 가능합니다. 1,000원 미만 잔돈은 머니플렉스 앱으로 충전 또는 기부만 가능합니다.",
    },
    {
      title:
        "환전한 한국 원화 (KRW)를 다시 외화(Foreign Currencies)로 되돌릴 수 있나요?",
      body: "키오스크에서 환전한 한국 원화를 다시 외화로 환전하는 기능은 제공되지 않습니다.",
    },
    {
      title: "이용 시간은 어떻게 되나요?",
      body: "이마트, 백화점, 호텔, 공공기관등에 설치되어 있는 키오스크마다 매장 영업시간내에 이용하실 수 있으며, 각 지점별 휴무일 및 영업시간이 다를수 있어 모바일앱에서 확인해주시기 바랍니다.",
    },
    {
      title: "사용 시 주의 사항이 있나요?",
      body: "키오스크를 이용할 때는 외국 화폐의 환율 변동이 있을 수 있으니, 환전 전 실시간 환율을 확인하시기 바랍니다.",
    },
    {
      title: "환전 가능한 통화는 어떤 것들이 있나요?",
      body: "머니플렉스 키오스크에서는 주요 국가의 외화뿐만 아니라, 다양한 국가의 통화를 지원합니다. 지원 화폐로는 미국 달러(USD), 유로(EUR), 일본 엔화(JPY), 중국 위안 (CNY), 홍콩(HKD), 대만(TWD), 싱가폴(SGD), 말레이시아(MYR), 필리핀(PHP), 호주(AUD), 태국(THB), 영국(GBP), 인도네시아(IDR), 베트남(VND), 러시아(RUB) 이며, 대내외 상황에 따라 조정 될 수 있습니다.자세한 통화별 권종은 모바일앱을 통해 확인해주시기 바랍니다.",
    },
    {
      title: "원화동전 교환은 가능한가요?",
      body: "현재 외화 동전 환전만 제공하고 있습니다. ",
    },
    {
      title: "환전 가능한 최소 및 최대 금액은 얼마인가요?",
      body: "머니플렉스 키오스크에서의 환전은 1인/1일 미국(USD)기준 2,000달러(Dollar) 이하까지 가능합니다.",
    },

    {
      title: "이용 시 보안에 대해 어떤 방법으로 관리되나요?",
      body: "머니플렉스는 외국환 거래규정에 따라 DB암호화, DB백업, 실시간 침입탐지 모니터링, 전용서버 방화벽, 통신구간암호화, 키패드암호화 등 최신 기술의 보안 시스템을 갖추고 있으며, 사용자 정보와 거래 과정을 안전하게 보호하고 있습니다. ",
    },
    {
      title: "외화 환전 외에 어떤 기능을 제공하나요?",
      body: "머니플렉스 키오스크는 외화 환전 외에도 선불 교통카드 발급, 외화 잔돈 기부, 여행 정보 제공, 관광 정보 안내 등 다양한 부가 서비스를 제공합니다.",
    },
    {
      title: "이용 중 문제가 발생했을 때 어떻게 대응하나요?",
      body: "키오스크 이용 중 문제 발생 시 고객센터(+82-1833-4283)로 연락주시면, 신속하게 대응하여 불편을 최소화하는데 최선을 다하겠습니다. ",
    },
    {
      title: "이용을 위해 추가 수수료가 있나요?",
      body: "기본적인 환전 수수료 외에 추가 수수료는 없습니다. 각 통화별 환전 수수료는 메인 화면에서 실시간 확인 가능합니다.",
    },
    {
      title: "이용하려면 먼저 가입을 해야 하나요?",
      body: "머니플렉스 키오스크 이용을 위한 별도의 가입 절차는 필요하지 않습니다. 신분증만 있다면 누구나 쉽게 이용 가능합니다.",
    },
    {
      title: "서비스 이용 가능한 지역은 어디인가요?",
      body: "이마트, 현대백화점 등 대형 쇼핑몰을 포함해 호텔 등 다양한 위치에 서비스를 제공하고 있으며, 계속해서 확대 중입니다. 이용 가능 지점 정보는 홈페이지 또는 모바일 앱을 통해 확인하실 수 있습니다.",
    },
    {
      title: "원화동전 교환은 가능한가요?",
      body: "현재 외화 동전 환전만 제공하고 있습니다. ",
    },
    {
      title: "이용 시간은 어떻게 되나요?",
      body: "머니플렉스 키오스크는 기본 365일 24시간 이용 가능합니다. 하지만 현장 특성 상 배치 된 지점의 영업시간 내 이용 가능합니다. 이용 전 개별 영업시간은 배치 된 지점의 매장 정보를 참고하여 주시기 바랍니다.",
    },

    {
      title: "이용 시 자주 묻는 질문 외의 문의 사항은 어디로 연락해야 하나요?",
      body: "자주 묻는 질문 외의 문의사항이 있을 경우 고객센터(1833-4283) 또는 홈페이지 실시간 상담 채팅으로 문의해주시면 친절하게 안내해드리겠습니다.",
    },

    {
      title: "KB국민지갑으로 충전은 어떻게 하나요?",
      body: "수령방법 선택 단계에서 KB국민지갑을 선택하시고 KB스타뱅킹앱을 실행하셔서 생성된 바코드를 스캔해주시면 됩니다. KB스타뱅킹앱의 국민지갑 충전 바코드 생성을 위해 “KB스타뱅킹앱-뱅킹회원가입-국민지갑-내 포인트-모으자-키오스크에서 쉽게 환전 받기-바코드 발급 받기” 로 진행하시면 됩니다.",
    },
  ],
  EN: [
    {
      title: "I want to know how to exchange foreign currency at the kiosk.",
      body: "There are two methods: foreign currency sale and foreign currency purchase. Foreign currency sale: Sell in real-time at the kiosk (insert foreign currency -> receive KRW; available for both foreigners and locals). Foreign currency purchase: Pre-book the exchange through the MoneyPlex app and then visit the kiosk to make the purchase (deposit KRW -> receive foreign currency) (locals only).",
    },
    {
      title: "How can I receive the money after inserting foreign currency?",
      body: "The Korean Won (KRW) exchanged at the kiosk can be received in cash immediately, and it can also be charged to the MoneyPlex app or KB National Wallet or donated. Change less than 1,000 KRW can only be charged or donated through the MoneyPlex app.",
    },
    {
      title: "Can I exchange the Korean Won (KRW) back into foreign currency?",
      body: "The function to exchange Korean Won exchanged at the kiosk back into foreign currency is not provided.",
    },
    {
      title: "What are the operating hours?",
      body: "Kiosks installed in places like E-Mart, department stores, hotels, and public institutions can be used during store operating hours, and each location may have different holidays and operating hours, so please check the mobile app.",
    },
    {
      title: "Are there any precautions when using?",
      body: "When using the kiosk, please check the real-time exchange rate as there may be fluctuations in foreign currency rates.",
    },
    {
      title: "What currencies can I exchange?",
      body: "The MoneyPlex kiosk supports not only major foreign currencies but also currencies from various countries. Supported currencies include USD, EUR, JPY, CNY, HKD, TWD, SGD, MYR, PHP, AUD, THB, GBP, IDR, VND, and RUB, and this may be adjusted depending on domestic and foreign conditions. Please check the mobile app for detailed currency types.",
    },
    {
      title: "Can I exchange Korean Won coins?",
      body: "Currently, only foreign currency coin exchanges are provided.",
    },
    {
      title: "What is the minimum and maximum amount that can be exchanged?",
      body: "Exchanges at the MoneyPlex kiosk are allowed up to 2,000 USD per person per day.",
    },
    {
      title: "How is security managed during use?",
      body: "MoneyPlex has a security system with the latest technologies such as DB encryption, DB backup, real-time intrusion detection monitoring, dedicated server firewalls, communication encryption, and keypad encryption, ensuring the safety of user information and transaction processes.",
    },
    {
      title:
        "What other functions are provided besides foreign currency exchange?",
      body: "In addition to foreign currency exchange, MoneyPlex kiosks provide various additional services such as issuing prepaid transportation cards, donating foreign currency change, providing travel information, and guiding tourism information.",
    },
    {
      title: "How do you respond if there is a problem during use?",
      body: "If a problem occurs while using the kiosk, please contact the customer service center (+82-1833-4283), and we will do our best to respond quickly and minimize inconvenience.",
    },
    {
      title: "Are there additional fees for use?",
      body: "There are no additional fees other than the basic exchange fee. The exchange fees for each currency can be checked in real-time on the main screen.",
    },
    {
      title: "Do I need to register first to use it?",
      body: "No special registration procedure is required to use the MoneyPlex kiosk. Anyone can easily use it if they have an ID.",
    },
    {
      title: "Where can I use the service?",
      body: "Services are provided in various locations, including large shopping malls like E-Mart and Hyundai Department Store, as well as hotels, and they are continuously expanding. Information on available locations can be checked on the website or Mobile app.",
    },
    {
      title: "What are the operating hours?",
      body: "The MoneyPlex kiosk is generally available 365 days a year, 24 hours a day. However, usage is subject to the operating hours of the specific location where it is installed. Please refer to the operating hours of the designated location before use.",
    },
    {
      title:
        "Where should I contact if I have inquiries other than frequently asked questions?",
      body: "For inquiries other than frequently asked questions, please contact the customer service center (1833-4283) or inquire through the real-time chat on the website, and we will kindly assist you.",
    },
    {
      title: "How do I charge to the KB National Wallet?",
      body: "In the step to select the method of receipt, choose the KB National Wallet and open the KB Star Banking app to scan the generated barcode.",
    },
  ],
  CN: [
    {
      title: "我想知道如何在自助服务机上兑换外币。",
      body: "有两种方法：外币出售和外币购买。外币出售：在自助服务机上实时出售（投币外币 -> 收取韩元，所有外国人和本国人均可使用）外币购买：在MoneyPlex应用程序中提前预约兑换，然后前往自助服务机进行购买（存入韩元 -> 收取外币）（仅限本国人）",
    },
    {
      title: "投入外币后，如何收回？",
      body: "在自助服务机兑换的韩元（KRW）可以立即以现金形式收取，也可以通过MoneyPlex应用程序和KB国民钱包进行充值或捐赠。1,000韩元以下的零钱只能通过MoneyPlex应用程序进行充值或捐赠。",
    },
    {
      title: "能否将兑换的韩元（KRW）再次兑换成外币（Foreign Currencies）？",
      body: "自助服务机上兑换的韩元不能再次兑换成外币。",
    },
    {
      title: "使用时间是怎样的？",
      body: "在乐天超市、百货商店、酒店和公共机构等安装的自助服务机可在商店营业时间内使用，休假和营业时间可能会因各地点而异，请在手机应用中确认。",
    },
    {
      title: "使用时有注意事项吗？",
      body: "在使用自助服务机时，请注意外币汇率可能会波动，兑换前请确认实时汇率。",
    },
    {
      title: "可以兑换哪些货币？",
      body: "MoneyPlex自助服务机不仅支持主要国家的外币，还支持多种国家的货币。支持的货币包括美元（USD）、欧元（EUR）、日元（JPY）、人民币（CNY）、港币（HKD）、新台币（TWD）、新加坡元（SGD）、马来西亚林吉特（MYR）、菲律宾比索（PHP）、澳大利亚元（AUD）、泰铢（THB）、英镑（GBP）、印尼盾（IDR）、越南盾（VND）、俄罗斯卢布（RUB），并可能根据国内外情况进行调整。详细的货币种类请通过手机应用确认。",
    },
    {
      title: "能否兑换韩元硬币？",
      body: "目前仅提供外币硬币的兑换。",
    },
    {
      title: "可以兑换的最小和最大金额是多少？",
      body: "在MoneyPlex自助服务机上的兑换金额为每人/每天基于美元（USD）最高可达2,000美元。",
    },
    {
      title: "在使用过程中安全是如何管理的？",
      body: "MoneyPlex根据外汇交易规定，具备数据库加密、数据库备份、实时入侵检测监控、专用服务器防火墙、通信区间加密、键盘加密等最新技术的安全系统，安全保护用户信息和交易过程。",
    },
    {
      title: "除了外币兑换外，还提供哪些功能？",
      body: "MoneyPlex自助服务机除了外币兑换外，还提供预付交通卡发放、外币零钱捐赠、旅行信息提供、旅游信息指导等多种增值服务。",
    },
    {
      title: "在使用中出现问题时，如何应对？",
      body: "如果在使用自助服务机时出现问题，请联系客户服务中心（+82-1833-4283），我们将迅速处理，尽力减少您的不便。",
    },
    {
      title: "使用需要额外费用吗？",
      body: "除了基本的兑换手续费外，没有其他额外费用。各货币的兑换手续费可以在主界面实时查看。",
    },
    {
      title: "使用前需要先注册吗？",
      body: "使用MoneyPlex自助服务机不需要额外的注册手续。只需有身份证明，任何人都可以轻松使用。",
    },
    {
      title: "可用服务区域在哪里？",
      body: "在包括乐天超市、现代百货等大型购物中心及酒店等多种地点提供服务，并持续扩大。可用地点信息可通过官网或安卓应用查看。",
    },
    {
      title: "使用时间是怎样的？",
      body: "MoneyPlex自助服务机基本上365天24小时可用。但由于现场情况，必须在分配地点的营业时间内使用。请在使用前参考指定位置的营业时间。",
    },
    {
      title: "如果有其他问题，应该联系哪里？",
      body: "如果有常见问题以外的咨询，请联系客户服务中心（1833-4283）或通过官网实时咨询聊天进行询问，我们将为您提供热情的服务。",
    },
    {
      title: "如何充值到KB国民钱包？",
      body: "在选择收款方式阶段选择KB国民钱包，打开KB星银行应用程序并扫描生成的二维码即可。为了生成KB星银行应用程序的国民钱包充值二维码，请依次进行“KB星银行应用程序-银行会员注册-国民钱包-我的积分-聚集-轻松在自助服务机上兑换-生成二维码”。",
    },
  ],
  CT: [
    {
      title: "我想知道如何在自助服務機上兌換外幣。",
      body: "有兩種方法：外幣出售和外幣購買。外幣出售：在自助服務機上即時出售（投入外幣 -> 收到韓元，內外籍人士均可使用）。外幣購買：提前通過MoneyPlex應用程式預約兌換，然後到自助服務機進行購買（存入韓元 -> 收到外幣）（僅限國內人）。",
    },
    {
      title: "投入外幣後我該如何領取款項？",
      body: "在自助服務機兌換的韓元（KRW）可以立即以現金形式領取，也可以充入MoneyPlex應用程式或KB國民錢包，或進行捐贈。少於1,000韓元的零錢只能通過MoneyPlex應用程式充入或捐贈。",
    },
    {
      title: "我可以將兌換的韓元（KRW）再兌換回外幣嗎？",
      body: "自助服務機上兌換的韓元不能再兌換成外幣。",
    },
    {
      title: "營業時間是怎樣的？",
      body: "安裝在超市、百貨公司、酒店和公共機構等地的自助服務機可以在商店營業時間內使用，各個地點的休息日和營業時間可能不同，請在手機應用中查看。",
    },
    {
      title: "使用時有哪些注意事項？",
      body: "使用自助服務機時，請檢查即時匯率，因為外幣匯率可能會波動。",
    },
    {
      title: "可以兌換哪些貨幣？",
      body: "MoneyPlex自助服務機不僅支持主要國家的外幣，還支持多種國家的貨幣。支持的貨幣包括美元（USD）、歐元（EUR）、日元（JPY）、人民幣（CNY）、港元（HKD）、新台幣（TWD）、新加坡元（SGD）、馬來西亞林吉特（MYR）、菲律賓比索（PHP）、澳元（AUD）、泰銖（THB）、英鎊（GBP）、印尼盾（IDR）、越南盾（VND）和俄羅斯盧布（RUB），具體貨幣可能會根據國內外情況調整。詳細的貨幣種類請通過手機應用查看。",
    },
    {
      title: "可以兌換韓元硬幣嗎？",
      body: "目前只提供外幣硬幣兌換服務。",
    },
    {
      title: "可兌換的最小和最大金額是多少？",
      body: "在MoneyPlex自助服務機的兌換上限為每人每天2,000美元（USD）。",
    },
    {
      title: "使用時的安全管理方式是什麼？",
      body: "MoneyPlex擁有最新技術的安全系統，如數據庫加密、數據庫備份、實時入侵檢測監控、專用伺服器防火牆、通信區域加密和鍵盤加密等，確保用戶信息和交易過程的安全。",
    },
    {
      title: "除了外幣兌換外，還提供哪些功能？",
      body: "MoneyPlex自助服務機除了外幣兌換外，還提供預付交通卡發行、外幣零錢捐贈、旅行信息提供和旅遊信息導覽等多種附加服務。",
    },
    {
      title: "在使用過程中出現問題時怎麼處理？",
      body: "如果在使用自助服務機時出現問題，請聯繫客服中心（+82-1833-4283），我們會盡快回應，以最小化不便。",
    },
    {
      title: "使用時是否有額外費用？",
      body: "除基本的兌換手續費外，沒有其他額外費用。每種貨幣的兌換手續費可以在主畫面上即時查看。",
    },
    {
      title: "使用前是否需要註冊？",
      body: "使用MoneyPlex自助服務機不需要特別的註冊程序。如果有身份證，任何人都可以輕鬆使用。",
    },
    {
      title: "可用服務區域在哪裡？",
      body: "在包括大商場如E-Mart、現代百貨及酒店等多種地點提供服務，並持續擴展。可用地點的信息可通過官網或安卓應用查看。",
    },
    {
      title: "營業時間是什麼？",
      body: "MoneyPlex自助服務機通常提供365天24小時的服務。但由於現場特性，需在安裝地點的營業時間內使用。使用前請參考指定地點的營業時間。",
    },
    {
      title: "如果有其他問題，應該聯繫哪裡？",
      body: "如有常見問題以外的查詢，請聯繫客服中心（1833-4283）或通過官網即時諮詢聊天進行詢問，我們將樂意提供幫助。",
    },
    {
      title: "如何充值到KB國民錢包？",
      body: "在選擇收款方式階段選擇KB國民錢包，然後打開KB星銀行應用程式，掃描生成的條碼。",
    },
  ],
  JP: [
    {
      title: "キオスクでの両替方法が知りたいです。",
      body: "外貨販売と外貨購入の2つの方法があります。外貨販売: キオスクでリアルタイムで販売（外貨投入 -> 韓国ウォン受取、キオスクでは外国人と内国人両方可能）外貨購入: マネーフレックスアプリで事前に両替予約をした後、キオスクを訪問して購入（韓国ウォン入金 -> 外貨受取）（内国人のみ対象）",
    },
    {
      title: "外貨を投入したら、どうやって受け取れますか？",
      body: "キオスクで両替した韓国ウォン（KRW）は即時に現金で受け取ることができ、マネーフレックスアプリやKB国民ウォレットにチャージまたは寄付が可能です。1,000ウォン未満の端数はマネーフレックスアプリでのチャージまたは寄付のみ可能です。",
    },
    {
      title:
        "両替した韓国ウォン（KRW）を再び外貨（Foreign Currencies）に戻すことはできますか？",
      body: "キオスクで両替した韓国ウォンを再び外貨に両替する機能は提供されていません。",
    },
    {
      title: "利用時間はどうなっていますか？",
      body: "イーマート、百貨店、ホテル、公的機関などに設置されているキオスクは、各店舗の営業時間内に利用可能で、各店舗の休業日や営業時間が異なる場合があるので、モバイルアプリで確認してください。",
    },
    {
      title: "利用時の注意事項はありますか？",
      body: "キオスクを利用する際は、外貨の為替レートの変動がある場合があるため、両替前にリアルタイムの為替レートを確認してください。",
    },
    {
      title: "両替できる通貨は何ですか？",
      body: "マネーフレックスキオスクでは、主要国の外貨だけでなく、さまざまな国の通貨もサポートしています。サポートされる通貨には、米ドル（USD）、ユーロ（EUR）、日本円（JPY）、中国元（CNY）、香港ドル（HKD）、台湾ドル（TWD）、シンガポールドル（SGD）、マレーシアリンギット（MYR）、フィリピンペソ（PHP）、オーストラリアドル（AUD）、タイバーツ（THB）、英国ポンド（GBP）、インドネシアルピア（IDR）、ベトナムドン（VND）、ロシアルーブル（RUB）が含まれ、国内外の状況に応じて調整される場合があります。詳細な通貨の種類は、モバイルアプリで確認してください。",
    },
    {
      title: "韓国ウォンの硬貨交換は可能ですか？",
      body: "現在、外貨の硬貨両替のみ提供しています。",
    },
    {
      title: "両替可能な最小および最大金額はどのくらいですか？",
      body: "マネーフレックスキオスクでの両替は、1人/1日米ドル（USD）基準で2,000ドル以下まで可能です。",
    },
    {
      title: "利用時のセキュリティはどのように管理されていますか？",
      body: "マネーフレックスは外国為替取引規則に従い、DB暗号化、DBバックアップ、リアルタイム侵入検知モニタリング、専用サーバーファイアウォール、通信区間暗号化、キーパッド暗号化などの最新技術のセキュリティシステムを備えており、ユーザー情報と取引プロセスを安全に保護しています。",
    },
    {
      title: "外貨両替以外にどのような機能を提供していますか？",
      body: "マネーフレックスキオスクは、外貨両替の他に、プリペイド交通カードの発行、外貨の端数寄付、旅行情報提供、観光情報案内など、さまざまな付加サービスを提供しています。",
    },
    {
      title: "利用中に問題が発生した場合、どのように対処しますか？",
      body: "キオスク利用中に問題が発生した場合は、カスタマーセンター（+82-1833-4283）に連絡いただければ、迅速に対応し、不便を最小限に抑えるよう努めます。",
    },
    {
      title: "利用のために追加手数料はありますか？",
      body: "基本的な両替手数料の他に追加手数料はありません。各通貨ごとの両替手数料はメイン画面でリアルタイムに確認できます。",
    },
    {
      title: "利用するためにはまず登録が必要ですか？",
      body: "マネーフレックスキオスク利用のために特別な登録手続きは必要ありません。身分証明書があれば誰でも簡単に利用できます。",
    },
    {
      title: "サービス利用可能地域はどこですか？",
      body: "イーマート、現代百貨店などの大型ショッピングモールを含め、ホテルなどさまざまな場所にサービスを提供しており、引き続き拡大中です。利用可能な店舗情報はホームページまたはAndroidアプリで確認できます。",
    },
    {
      title: "利用時間はどうなっていますか？",
      body: "マネーフレックスキオスクは基本的に365日24時間利用可能です。しかし、現場の特性上、配置された店舗の営業時間内に利用可能です。利用前に個別の営業時間は配置された店舗の情報を参考にしてください。",
    },
    {
      title:
        "利用時によくある質問以外の問い合わせはどこに連絡すればいいですか？",
      body: "よくある質問以外の問い合わせがある場合は、カスタマーセンター（1833-4283）またはホームページのリアルタイムチャットでお問い合わせいただければ、親切にご案内いたします。",
    },
    {
      title: "KB国民ウォレットへのチャージはどうすればいいですか？",
      body: "受取方法選択段階でKB国民ウォレットを選択し、KBスター銀行アプリを起動して生成されたバーコードをスキャンしてください。KBスター銀行アプリの国民ウォレットチャージバーコードを生成するために、「KBスター銀行アプリ-銀行会員登録-国民ウォレット-私のポイント-集めよう-キオスクで簡単に両替を受ける-バーコードを発行する」と進めてください。",
    },
  ],
  TH: [
    {
      title: "ฉันอยากรู้วิธีแลกเงินต่างประเทศที่เคาน์เตอร์บริการอัตโนมัติ",
      body: "มีสองวิธีคือ ขายเงินตราต่างประเทศและซื้อเงินตราต่างประเทศ การขายเงินตราต่างประเทศ: ขายแบบเรียลไทม์ที่เคาน์เตอร์ (ใส่เงินตราต่างประเทศ -> รับเงินวอนเกาหลี ทุกคนสามารถใช้ได้) การซื้อเงินตราต่างประเทศ: จองแลกเงินล่วงหน้าผ่านแอป MoneyPlex และไปที่เคาน์เตอร์เพื่อทำการซื้อ (ฝากเงินวอน -> รับเงินตราต่างประเทศ) (เฉพาะคนในประเทศ)",
    },
    {
      title: "เมื่อใส่เงินตราต่างประเทศแล้วฉันจะได้รับเงินอย่างไร",
      body: "เงินวอนเกาหลี (KRW) ที่แลกที่เคาน์เตอร์สามารถรับเป็นเงินสดได้ทันที และสามารถเติมเข้าที่แอป MoneyPlex หรือกระเป๋าเงิน KB หรือบริจาคได้ เศษเงินที่ต่ำกว่า 1,000 วอนสามารถเติมหรือบริจาคได้ผ่านแอป MoneyPlex เท่านั้น",
    },
    {
      title: "ฉันสามารถแลกเงินวอนเกาหลี (KRW) กลับเป็นเงินตราต่างประเทศได้ไหม",
      body: "ฟังก์ชันการแลกเงินวอนเกาหลีที่แลกที่เคาน์เตอร์กลับเป็นเงินตราต่างประเทศไม่มีให้บริการ",
    },
    {
      title: "เวลาทำการเป็นอย่างไร",
      body: "เคาน์เตอร์ที่ติดตั้งในสถานที่ต่าง ๆ เช่น อีมาร์ท ห้างสรรพสินค้า โรงแรม และหน่วยงานสาธารณะ สามารถใช้งานได้ในเวลาทำการของร้านค้า และวันหยุดและเวลาทำการจะแตกต่างกันไปตามแต่ละสถานที่ กรุณาตรวจสอบในแอปมือถือ",
    },
    {
      title: "มีข้อควรระวังอะไรเมื่อใช้ไหม",
      body: "เมื่อใช้เคาน์เตอร์กรุณาตรวจสอบอัตราแลกเปลี่ยนแบบเรียลไทม์ เนื่องจากอาจมีการเปลี่ยนแปลงอัตราแลกเปลี่ยนเงินตราต่างประเทศ",
    },
    {
      title: "สามารถแลกเงินสกุลใดได้บ้าง",
      body: "เคาน์เตอร์ MoneyPlex รองรับทั้งเงินตราต่างประเทศที่สำคัญและเงินสกุลจากหลากหลายประเทศ เงินสกุลที่รองรับ ได้แก่ USD, EUR, JPY, CNY, HKD, TWD, SGD, MYR, PHP, AUD, THB, GBP, IDR, VND และ RUB โดยอาจมีการปรับเปลี่ยนตามสถานการณ์ทั้งในและต่างประเทศ สามารถตรวจสอบประเภทเงินสกุลโดยละเอียดได้ในแอปมือถือ",
    },
    {
      title: "สามารถแลกเหรียญวอนเกาหลีได้หรือไม่",
      body: "ขณะนี้ให้บริการเฉพาะการแลกเหรียญเงินตราต่างประเทศ",
    },
    {
      title: "จำนวนเงินขั้นต่ำและสูงสุดที่แลกได้คือเท่าไร",
      body: "การแลกเงินที่เคาน์เตอร์ MoneyPlex สามารถทำได้สูงสุด 2,000 USD ต่อคนต่อวัน",
    },
    {
      title: "การรักษาความปลอดภัยในการใช้งานมีการจัดการอย่างไร",
      body: "MoneyPlex มีระบบรักษาความปลอดภัยที่ทันสมัย เช่น การเข้ารหัสฐานข้อมูล การสำรองข้อมูลฐานข้อมูล การตรวจสอบการบุกรุกแบบเรียลไทม์ ไฟร์วอลล์ของเซิร์ฟเวอร์เฉพาะทาง การเข้ารหัสข้อมูลในการสื่อสาร และการเข้ารหัสแป้นพิมพ์ เพื่อปกป้องข้อมูลผู้ใช้และกระบวนการทำธุรกรรมอย่างปลอดภัย",
    },
    {
      title: "นอกจากการแลกเงินต่างประเทศแล้วยังมีฟังก์ชันอะไรอีกบ้าง",
      body: "เคาน์เตอร์ MoneyPlex นอกจากการแลกเงินต่างประเทศ ยังมีบริการเสริมอื่น ๆ เช่น การออกบัตรโดยสารพรีเพด การบริจาคเศษเงินต่างประเทศ การให้ข้อมูลการเดินทาง และการให้ข้อมูลท่องเที่ยว",
    },
    {
      title: "หากมีปัญหาเกิดขึ้นระหว่างการใช้งานจะมีการตอบสนองอย่างไร",
      body: "หากมีปัญหาเกิดขึ้นระหว่างการใช้เคาน์เตอร์ กรุณาติดต่อศูนย์บริการลูกค้า (+82-1833-4283) เราจะพยายามตอบสนองอย่างรวดเร็วเพื่อให้ความไม่สะดวกน้อยที่สุด",
    },
    {
      title: "มีค่าธรรมเนียมเพิ่มเติมในการใช้งานหรือไม่",
      body: "ไม่มีค่าธรรมเนียมเพิ่มเติมนอกจากค่าธรรมเนียมการแลกเงินพื้นฐาน ค่าธรรมเนียมการแลกเงินของแต่ละสกุลเงินสามารถตรวจสอบได้แบบเรียลไทม์จากหน้าหลัก",
    },
    {
      title: "ฉันต้องลงทะเบียนก่อนใช้งานหรือไม่",
      body: "ไม่จำเป็นต้องมีขั้นตอนการลงทะเบียนพิเศษในการใช้เคาน์เตอร์ MoneyPlex ใครก็สามารถใช้ได้ง่ายหากมีบัตรประจำตัว",
    },
    {
      title: "บริการสามารถใช้งานได้ที่ไหน",
      body: "มีการให้บริการในสถานที่ต่าง ๆ รวมถึงห้างสรรพสินค้าขนาดใหญ่เช่น อีมาร์ทและห้างสรรพสินค้าเกาหลี และโรงแรม โดยยังคงขยายต่อไป ข้อมูลเกี่ยวกับสถานที่ที่สามารถใช้บริการได้สามารถตรวจสอบได้ทางเว็บไซต์หรือแอปพลิเคชัน Android",
    },
    {
      title: "เวลาทำการเป็นอย่างไร",
      body: "เคาน์เตอร์ MoneyPlex มีให้บริการพื้นฐาน 365 วัน 24 ชั่วโมง อย่างไรก็ตาม ขึ้นอยู่กับสถานที่การใช้งานจะต้องใช้ในเวลาทำการของสถานที่ที่ติดตั้ง กรุณาตรวจสอบเวลาทำการของสถานที่นั้นก่อนใช้งาน",
    },
    {
      title: "หากมีคำถามอื่น ๆ นอกเหนือจากคำถามที่พบบ่อยจะต้องติดต่อที่ไหน",
      body: "หากมีคำถามอื่น ๆ นอกเหนือจากคำถามที่พบบ่อย กรุณาติดต่อศูนย์บริการลูกค้า (1833-4283) หรือสอบถามผ่านการสนทนาออนไลน์บนเว็บไซต์ เราจะให้ความช่วยเหลืออย่างสุภาพ",
    },
    {
      title: "จะเติมเงินเข้ากระเป๋าเงิน KB ได้อย่างไร",
      body: "ในขั้นตอนการเลือกวิธีการรับเงิน เลือกกระเป๋าเงิน KB และเปิดแอป KB Star Banking เพื่扫描บาร์โค้ดที่สร้างขึ้น",
    },
  ],
};
const FaqTotal = {
  KR: [...FaqApp.KR, ...FaqKiosk.KR],
  TH: [...FaqApp.TH, ...FaqKiosk.TH],
  EN: [...FaqApp.EN, ...FaqKiosk.EN],
  CN: [...FaqApp.CN, ...FaqKiosk.CN],
  CT: [...FaqApp.CT, ...FaqKiosk.CT],
  JP: [...FaqApp.JP, ...FaqKiosk.JP],
};
export { FaqETC, FaqKiosk, FaqApp, FaqTotal };

import axios from "axios";
import React, { useEffect, useState } from "react";
import { LanguageProps } from "types/Type";
import string from "locales";
import Check from "asset/image/Check.png";
import CheckYellow from "asset/image/CheckYellow.png";
import { fontSize24, fontSize26, fontSize32 } from "const/fontSize";
import { useNavigate } from "react-router-dom";
export default function InqeryForm({ userLanguage }: LanguageProps) {
  const [companyName, setCompanyName] = useState("");
  const [number, setNumber] = useState<string>("");
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const submitHandler = () => {
    let config = {
      method: "post",
      url: "/mpx_api/contact",
      headers: {
        "X-API-KEY": process.env.REACT_APP_MPX_API_KEY,
        "Content-Type": "application/json",
      },
      data: {
        name: companyName,
        contact: number,
        user_check: "Y",
      },
    };
    if (companyName && number && check) {
      axios.request(config).then(() => {
        alert(string[userLanguage].InquerySuccess);
        navigate("/");
      });
    }
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className=" w-[40%]">
        <div className="mt-[70px] text-[20px] mb-[10px]">
          {string[userLanguage].InqueryFormName}
          <span className=" text-redcolorFF">*</span>
        </div>
        <input
          value={companyName}
          placeholder={string[userLanguage].InqueryPlaceHolderName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          className="border border-solid border-borederA2 rounded-[10px] text-20 w-full p-[20px]"
        />
        <div className="mt-40  text-20 mb-[10px]">
          {string[userLanguage].InqueryFormNumber}
          <span className=" text-redcolorFF">*</span>
        </div>
        <input
          inputMode="tel"
          placeholder={string[userLanguage].InqueryPlaceHolderNumber}
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          className="border border-solid border-borederA2 rounded-[10px] w-full text-20  p-[20px]"
        />
        <div className="mt-[40px] text-20 mb-[10px]">
          {string[userLanguage].InqueryFormPrivate}
          <span className=" text-redcolorFF">*</span>
        </div>
        <div className="  border border-solid border-borederA2 rounded-10  p-20 h-[68px]">
          <div
            onClick={() => {
              setCheck((prev) => !prev);
            }}
            className={`cursor-pointer flex items-center text-20 w-[70%]  gap-x-15`}
          >
            {check ? (
              <img
                style={{
                  width: 33,
                }}
                src={CheckYellow}
              />
            ) : (
              <img
                style={{
                  width: 33,
                }}
                src={Check}
              />
            )}

            {string[userLanguage].InqueryCheckText}
          </div>
        </div>
        <span className="mt-12 text-20 inline-block font-medium text-borederA2">
          {string[userLanguage].InqueryPrivateText}
        </span>
      </div>

      <button
        onClick={submitHandler}
        className={`${
          companyName && number && check ? "bg-primary" : "bg-[#F0F2F4]"
        } min-w-200 mt-60 mb-150  p-10 w-[20%] h-94 text-30 text-white font-bold rounded-10`}
      >
        {string[userLanguage].InqueryApply}
      </button>
    </div>
  );
}

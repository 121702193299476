import React from "react";

import YellowArray from "asset/image/YellowArray.png";
import { fontSize32 } from "const/fontSize";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { GUIDE_URL } from "const/const";
import { LanguageCode } from "types/Type";
import string from "locales";
import StarIcon from "asset/image/StarIcon1.png";

export default function GuideDetailPage() {
  const location = useLocation();
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const RenderComponent = ({
    array,
    title,
    highlight,
    subtitle,
    primary,
    OrNumber = [],
  }: {
    array: [string, string?][];
    title: string;
    highlight?: string;
    subtitle?: string;
    primary?: number[];
    OrNumber?: number[];
  }) => {
    return (
      <>
        <div
          className={`${fontSize32} font-medium mt-100 mb-50 flex items-baseline`}
        >
          {title}
          {subtitle && (
            <div className="text-24 ml-15 ">
              1000원 미만은 원화 수령시 머니플렉스 충전 또는 기부하기만
              가능합니다.
            </div>
          )}
        </div>
        <div className="flex  flex-wrap gap-y-90 items-center">
          {array.map((v, i, arr) => {
            let parts;
            if (highlight) {
              parts = v[1]?.split(highlight);
            }
            return (
              <div className=" flex ">
                <div className={`flex flex-row items-center justify-center`}>
                  <div className=" relative">
                    <div
                      className={`${
                        primary?.includes(i)
                          ? "text-white bg-primary"
                          : "text-primary"
                      } min-w-[315px] w-[100%] h-88 flex items-center justify-center  text-30  px-15 font-medium  text-center py-10 border-solid border border-primary rounded-40`}
                    >
                      {v[0]}
                    </div>
                    {v[1] && (
                      <div
                        style={{
                          fontFamily: "GmarketSans",
                          whiteSpace: "pre-line",
                        }}
                        className="p-10 px-10 w-[115%] text-nowrap flex absolute mt-25 left-[-20px] text-center items-center justify-center text-16 bg-[#FFF2D4] leading-20"
                      >
                        <img className="mr-10" src={StarIcon}></img>
                        {highlight ? (
                          <div>
                            {parts?.map((part, index) => {
                              return (
                                <span key={index}>
                                  {part}
                                  {index < parts.length - 1 && (
                                    <span
                                      style={{
                                        color: "red",
                                        display: "inline",
                                      }}
                                    >
                                      {highlight}
                                    </span>
                                  )}
                                </span>
                              );
                            })}
                          </div>
                        ) : (
                          v[1]
                        )}
                      </div>
                    )}
                  </div>

                  {i !== arr.length - 1 &&
                    (OrNumber?.includes(i) ? (
                      <div className="mx-20 text-30 text-primary">or</div>
                    ) : (
                      <img
                        className="mx-25"
                        style={{
                          width: 15,
                          objectFit: "contain",
                        }}
                        src={YellowArray}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const RanderTextCoin = ({
    text1,
    text2,
  }: {
    text1?: string;
    text2?: string;
  }) => {
    return (
      <>
        <div>{text1}</div>
        <div>{text2}</div>
        <div className="h-1 w-full bg-[#D9D9D9] my-25"></div>
      </>
    );
  };

  const renderText = () => {
    switch (userLanguage) {
      case "KR":
        return (
          <>
            키오스크 지점별 가능한 통화 권종은{" "}
            <span className="text-[#FF4949] ml-5"> 다를수 있습니다</span>.
            머니플렉스 앱에서 반드시 확인해주세요
          </>
        );
      case "EN":
        return (
          <>
            The available currency denominations{" "}
            <span className="text-[#FF4949] ml-5">
              {" "}
              may vary by kiosk location
            </span>
            . Please check the MoneyPlex app for confirmation.
          </>
        );
      case "JP":
        return (
          <>
            キオスクの地点によって利用可能な通貨の種類が異なる
            <span className="text-[#FF4949] ml-5"> 場合があります</span>.
            必ずマネーフレックスアプリで確認してください。{" "}
          </>
        );
      case "CN":
        return (
          <>
            各个自助终端的可用货币面额
            <span className="text-[#FF4949] ml-5">可能有所不同</span>.
            请务必在MMoneyPlex应用中确认。{" "}
          </>
        );

      case "CT":
        return (
          <>
            各个自助终端的可用货币面额
            <span className="text-[#FF4949] ml-5">可能有所不同</span>.
            请务必在MoneyPlex应用中确认。{" "}
          </>
        );
      case "TH": {
        return (
          <>
            สกุลเงินที่สามารถใช้ได้ที่แต่ละจุดบริการอ{" "}
            <span className="text-[#FF4949] ml-5">าจแตกต่างกัน</span>
            กรุณาตรวจสอบในแอป MoneyPlex ให้แน่ใจ
          </>
        );
      }
    }
  };

  const selectComponent = () => {
    if (location.pathname.includes(GUIDE_URL.APP_COUPON)) {
      return (
        <RenderComponent
          array={[
            [
              string[userLanguage].GuideDetailAppConponText1,
              string[userLanguage].GuideToolTip1,
            ],
            [string[userLanguage].GuideDetailAppConponText2],
            [string[userLanguage].GuideDetailAppConponText3],
            [string[userLanguage].GuideDetailAppConponText4],
          ]}
          title={string[userLanguage].GuideAppCoupon}
        />
      );
    }
    if (location.pathname.includes(GUIDE_URL.APP_SIGNUP)) {
      return (
        <RenderComponent
          array={[
            [string[userLanguage].GuideDetailSignUp1],
            [string[userLanguage].GuideDetailSignUp2],
            [string[userLanguage].GuideDetailSignUp3],
            [
              string[userLanguage].GuideDetailSignUp4,
              string[userLanguage].GuideToolTip2,
            ],
            [
              string[userLanguage].GuideDetailSignUp5,
              string[userLanguage].GuideToolTip3,
            ],
            [string[userLanguage].GuideDetailSignUp6],
          ]}
          title={string[userLanguage].GuideAppCoupon}
        />
      );
    }
    if (location.pathname.includes(GUIDE_URL.APP_RESERVATION)) {
      return (
        <RenderComponent
          highlight={string[userLanguage].HighlightText}
          array={[
            [string[userLanguage].GuideDetailAppReservation1],
            [string[userLanguage].GuideDetailAppReservation2],
            [
              string[userLanguage].GuideDetailAppReservation3,
              string[userLanguage].GuideToolTip4,
            ],
            [string[userLanguage].GuideDetailAppReservation4],
            [string[userLanguage].GuideDetailAppReservation6],
            [
              string[userLanguage].GuideDetailAppReservation7,
              string[userLanguage].GuideToolTip5,
            ],
            [
              string[userLanguage].GuideDetailAppReservation8,
              string[userLanguage].GuideToolTip6,
            ],
            [string[userLanguage].GuideDetailAppReservation9],
          ]}
          title={string[userLanguage].GuideAppReservation}
        />
      );
    }
    if (location.pathname.includes(GUIDE_URL.KIOSK_BILL)) {
      return (
        <>
          <div className="flex relative items-center">
            <div className={`${fontSize32} font-bold mt-62 mb-40 mr-20 `}>
              {string[userLanguage].GuideKioskBill}
            </div>
            <div
              style={{
                fontFamily: "GmarketSans",
                whiteSpace: "pre-line",
              }}
              className="p-10 px-10 relative top-5 text-24 text-nowrap flex  text-center items-center justify-center bg-[#FFF2D4] leading-20"
            >
              <img className="mr-10" src={StarIcon}></img>
              {renderText()}
            </div>
          </div>

          <div className="text-32 leading-50 font-medium ">
            <RanderTextCoin
              text1={`USD ${string[userLanguage].GuideBill} (100, 50, 20, 10, 5, 2, 1)`}
              text2={`USD ${string[userLanguage].GuideCoin} (1, 0.25)`}
            />
            <RanderTextCoin
              text1={`EUR ${string[userLanguage].GuideBill} (100, 50, 20, 10, 5)`}
              text2={`EUR ${string[userLanguage].GuideCoin} (2, 1, 0.5)`}
            />
            <RanderTextCoin
              text1={`CNY ${string[userLanguage].GuideBill} (100, 50, 20, 10, 5, 1)`}
              text2={`CNY ${string[userLanguage].GuideCoin} (1)`}
            />{" "}
            <RanderTextCoin
              text1={`JPY ${string[userLanguage].GuideBill}(10000, 5000, 1000)(${string[userLanguage].GuideOldBank}/${string[userLanguage].GuideNewBank}), ${string[userLanguage].GuideBill}(2000)`}
              text2={`JPY ${string[userLanguage].GuideCoin} (2, 1, 0.5)`}
            />{" "}
            <RanderTextCoin
              text1={`CAD ${string[userLanguage].GuideBill} (100, 50)`}
              text2={`CAD ${string[userLanguage].GuideCoin} (2, 1)`}
            />{" "}
            <RanderTextCoin
              text1={`CHF ${string[userLanguage].GuideBill} (2, 1)`}
            />{" "}
            <RanderTextCoin
              text1={`HKD ${string[userLanguage].GuideBill} (1000, 500, 100, 50, 20, 10)`}
              text2={`HKD ${string[userLanguage].GuideCoin} (2, 1)`}
            />{" "}
            <RanderTextCoin
              text1={`SGD ${string[userLanguage].GuideBill} (100, 50, 10, 5, 2)`}
              text2={`SGD ${string[userLanguage].GuideCoin} (1, 0.5)`}
            />{" "}
            <RanderTextCoin
              text1={`AUD ${string[userLanguage].GuideBill} (100, 50)`}
            />{" "}
            <RanderTextCoin
              text1={`GBP ${string[userLanguage].GuideBill} (20, 10, 5)`}
            />{" "}
            <RanderTextCoin
              text1={`PHP ${string[userLanguage].GuideBill} (1000,500,200,100)`}
            />
            <RanderTextCoin
              text1={`IDR ${string[userLanguage].GuideBill} (100000, 50000, 20000, 10000)`}
            />{" "}
            <RanderTextCoin
              text1={`MYR ${string[userLanguage].GuideBill} (100, 50, 20, 10)`}
            />{" "}
            <RanderTextCoin
              text1={`VND ${string[userLanguage].GuideBill} (500000, 200000, 100000, 50000)`}
            />{" "}
            <RanderTextCoin
              text1={`TWD ${string[userLanguage].GuideBill} (1000, 500, 200, 100)`}
              text2={`TWD ${string[userLanguage].GuideCoin} (50, 10)`}
            />{" "}
            <RanderTextCoin
              text1={`THB ${string[userLanguage].GuideBill} (1000, 500, 100, 50, 20)`}
              text2={`THB ${string[userLanguage].GuideCoin} (10)`}
            />{" "}
          </div>
        </>
      );
    }

    if (location.pathname.includes(GUIDE_URL.KIOSK_CURRENCY_EXCHANGE)) {
      return (
        <RenderComponent
          array={[
            [string[userLanguage].GuideDetailKioskCurrencyExchange1],
            [string[userLanguage].GuideDetailKioskCurrencyExchange2],
            [string[userLanguage].GuideDetailKioskCurrencyExchange3],
            [string[userLanguage].GuideDetailKioskCurrencyExchange4],
          ]}
          title={string[userLanguage].GuideKioskCurrencyExchange}
        />
      );
    }

    if (location.pathname.includes(GUIDE_URL.KIOSK_POINT)) {
      return (
        <div>
          <div className={`${fontSize32} font-bold mt-62 `}>
            {string[userLanguage].GuideKioskPoint}
          </div>
          <div className="mt-36 text-32 mb-30">
            01 {string[userLanguage].GuideDetailKioskPointSubTitle1}
          </div>
          <div className="flex  flex-wrap gap-y-36 items-center">
            {[
              [string[userLanguage].GuideDetailKioskPointApp1],
              [string[userLanguage].GuideDetailKioskPointApp2],
              [string[userLanguage].GuideDetailKioskPointApp3],
              [string[userLanguage].GuideDetailKioskPointApp5],
              [string[userLanguage].GuideDetailKioskPointApp6],
            ].map((v, i, arr) => {
              const versionParts = v[0].split("-");
              return (
                <div className="flex">
                  <div className={`flex flex-row items-center justify-center`}>
                    {i == 2 || i == 3 ? (
                      <div className="min-w-[315px] w-[100%]  flex items-center justify-center break-words h-88 text-30  px-15 font-medium text-primary text-center py-10 border-solid border border-primary rounded-40 ">
                        {`0${3}`}
                        {versionParts.map((part, index) => (
                          <span
                            className={`${
                              index === 0
                                ? "text-[#FF4949] mx-5"
                                : "text-primary"
                            }`}
                            key={index}
                          >
                            {index === 0 ? part : `-${part}`}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <div className="min-w-[315px] w-[100%]  flex items-center justify-center break-words h-88 text-30  px-15 font-medium text-primary text-center py-10 border-solid border border-primary rounded-40 ">
                        {`0${i >= 4 ? i : i + 1} ${v[0]}`}
                      </div>
                    )}

                    {i !== arr.length - 1 &&
                      (i === 2 ? (
                        <div className="mx-20 text-30 text-primary">or</div>
                      ) : (
                        <img
                          className="mx-25"
                          style={{
                            width: 15,
                            objectFit: "contain",
                          }}
                          src={YellowArray}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-110 text-32 mb-30">
            02 {string[userLanguage].GuideDetailKioskPointSubTitle2}
          </div>
          <div className="flex  flex-wrap gap-y-36 items-center">
            {[
              [string[userLanguage].GuideDetailKioskKb1],
              [string[userLanguage].GuideDetailKioskKb2],
              [string[userLanguage].GuideDetailKioskKb3],
              [string[userLanguage].GuideDetailKioskKb4],
              [string[userLanguage].GuideDetailKioskKb5],
              [string[userLanguage].GuideDetailKioskKb6],
            ].map((v, i, arr) => {
              return (
                <div className=" flex ">
                  <div className={`flex flex-row items-center justify-center`}>
                    <div className="min-w-[315px] w-[100%]  flex items-center justify-center  h-88 text-30  px-15 font-medium text-primary text-center py-10 border-solid border border-primary rounded-40 ">
                      {`0${i + 1} ${v[0]}`}
                    </div>
                    {i !== arr.length - 1 && (
                      <img
                        className="mx-25"
                        style={{
                          width: 15,
                          objectFit: "contain",
                        }}
                        src={YellowArray}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (location.pathname.includes(GUIDE_URL.KIOSK_RECIVE_MONEY)) {
      return (
        <div>
          <div className={`${fontSize32} font-medium mt-65 mb-[-30px]`}>
            {string[userLanguage].GuideKioskRecive}
          </div>

          <RenderComponent
            array={[
              [string[userLanguage].GuideDetailReceiveMoney1_1],
              [string[userLanguage].GuideDetailReceiveMoney1_2],
              [
                string[userLanguage].GuideDetailReceiveMoney1_3,
                "회원일경우-바코드 스캔",
              ],
              [
                string[userLanguage].GuideDetailReceiveMoney1_4,
                "비회원일경우-휴대폰번호입력",
              ],
              [string[userLanguage].GuideDetailReceiveMoney1_5],
              [string[userLanguage].GuideDetailReceiveMoney1_6],
            ]}
            primary={[4]}
            title={"01 " + string[userLanguage].GuideDetailReceiveMoneyTitle1}
            subtitle={
              "1000원 미만은 원화 수령시 머니플렉스 충전 또는 기부하기만 가능합니다."
            }
          />

          <div className="mt-100 text-32 mb-30">
            {"02 " + string[userLanguage].GuideDetailReceiveMoneySubTitle3}
          </div>
          <div className="flex  flex-wrap gap-y-36 items-center">
            {[
              [string[userLanguage].GuideDetailReceiveMoney3_1],
              [string[userLanguage].GuideDetailReceiveMoney3_2],
              [string[userLanguage].GuideDetailReceiveMoney3_3],
              [string[userLanguage].GuideDetailReceiveMoney3_4],
              [string[userLanguage].GuideDetailReceiveMoney3_5],
            ].map((v, i, arr) => {
              const versionParts = v[0].split("-");
              return (
                <div className="flex">
                  <div className={`flex flex-row items-center justify-center`}>
                    {i == 2 || i == 3 ? (
                      <div className="min-w-[315px] w-[100%]  flex items-center justify-center break-words h-88 text-30  px-15 font-medium text-primary text-center py-10 border-solid border border-primary rounded-40 ">
                        {versionParts.map((part, index) => (
                          <span
                            className={`${
                              index === 0
                                ? "text-[#FF4949] mx-5"
                                : "text-primary"
                            }`}
                            key={index}
                          >
                            {index === 0 ? part : `-${part}`}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <div className="min-w-[315px] w-[100%]  flex items-center justify-center break-words h-88 text-30  px-15 font-medium text-primary text-center py-10 border-solid border border-primary rounded-40 ">
                        {`${v[0]}`}
                      </div>
                    )}

                    {i !== arr.length - 1 &&
                      (i === 2 ? (
                        <div className="mx-20 text-30 text-primary">or</div>
                      ) : (
                        <img
                          className="mx-25"
                          style={{
                            width: 15,
                            objectFit: "contain",
                          }}
                          src={YellowArray}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
          <RenderComponent
            array={[
              [string[userLanguage].GuideDetailReceiveMoney4_1],
              [string[userLanguage].GuideDetailReceiveMoney4_2],
              [string[userLanguage].GuideDetailReceiveMoney4_3],
              [string[userLanguage].GuideDetailReceiveMoney4_4],
            ]}
            title={
              "03 " + string[userLanguage].GuideDetailReceiveMoneySubTitle4
            }
          />
          <RenderComponent
            array={[
              [string[userLanguage].GuideDetailReceiveMoney5_1],
              [string[userLanguage].GuideDetailReceiveMoney5_2],
            ]}
            title={
              "04 " + string[userLanguage].GuideDetailReceiveMoneySubTitle5
            }
          />
        </div>
      );
    }
    if (location.pathname.includes(GUIDE_URL.KIOSK_CARD_ISSUE)) {
      return (
        <div className="flex flex-col items-center ">
          <div className="text-32 text-center mt-199">
            {string[userLanguage].GuideDetailCardInfo}
          </div>
          <Link
            to={
              "https://www.klook.com/ja/tetris/promo/japan-korea-destination-campaign/?spm=Home.CentralBanner%3Aany%3A%3AMwebHomeCampaignBanner%3ACampaign_LIST&clickId=6fff2a7e2b"
            }
          >
            <div className=" w-[262px] h-[67px] text-center bg-primary p-20 rounded-10 mt-30 text-white text-30 font-bold ">
              {string[userLanguage].GotoKlook}
            </div>
          </Link>
        </div>
      );
    }
  };
  return (
    <div className=" flex flex-col px-[10%] mb-150">{selectComponent()}</div>
  );
}

import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import VaildMobile from "util/VaildMobile";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import useUserLanguage from "hooks/useUserLanguage";
import GotoTop from "asset/image/GotoTop.png";
import ChatIcon from "asset/image/ChatIcon4.png";
import FaqIcon from "asset/image/FaqIcon.png";
export default function Layout() {
  const location = useLocation();
  const windowSize = useWindowSizeCustom();
  const [userLanguage, setUserLanguage] = useUserLanguage();

  const isMobile = windowSize.width < 900 ? true : false;
  const [isHomePage, setIsHomePage] = useState(true);
  const [changeText, setChangeText] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [location.pathname]);
  return (
    <div>
      <Header userLanguage={userLanguage} setUserLanguage={setUserLanguage} />
      <div
        style={{
          marginTop: isHomePage || isMobile ? 0 : 75,
        }}
        className="min-h-[calc(100vh-422px)]"
      >
        <Outlet context={{ userLanguage }} />
      </div>
      <Footer />
      <div className="bottom-50 fixed w-50 right-[5%] z-9999">
        <Link to={"/custermerservice"}>
          <img src={FaqIcon} className="mb-22"></img>
        </Link>
        <Link to={"https://l7kye.channel.io/home"} target="_blank">
          <img src={ChatIcon} className="mb-22"></img>
        </Link>
        <img
          onClick={() => {
            window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
          }}
          src={GotoTop}
          className=""
        ></img>
      </div>
    </div>
  );
}

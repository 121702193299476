import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import KioskMarker from "asset/image/KioskMarker.png";
import SelectKioskMarker from "asset/image/SelectKioskMarker.png";
import ArrowKiosk from "asset/image/ArrowKiosk.png";
import { LanguageCode } from "types/Type";
import string from "locales";
const containerStyle = { width: "1550px", height: "965px" };

const center = {
  lat: 37.573209,
  lng: 127.0228748,
};

function Map({
  kioskData,
  selectedMarker,
  setSelectedMarker,
  map,
  setMap,
  userLanguage,
  currentLanguage,
}: {
  kioskData: any;
  selectedMarker: any;
  setSelectedMarker: any;
  map: any;
  setMap: any;
  userLanguage: LanguageCode;
  currentLanguage: string;
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDathZmmnFJPFFz-OO7QHVlOwg-ah0ZIRQ",
  });
  const [kisokGps, setKioskGps] = useState<any>([]);
  useEffect(() => {
    setKioskGps(() => {
      let totalArray: string[] = [];
      Object.values(kioskData).map((v: any) => totalArray.push(...v));
      return totalArray;
    });
  }, []);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setOptions({ zoom: 15 });

    setMap(map);
  }, []);

  useEffect(() => {
    console.log(selectedMarker);
    if (selectedMarker === undefined) {
      setSelectedMarker(kioskData[string[userLanguage as "EN"]["서울"]]?.[0]);
    }
  }, [userLanguage]);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  return (
    <div className=" relative flex flex-row  justify-center">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={
          selectedMarker === undefined
            ? center
            : {
                lat: +selectedMarker?.kiosk_map_y,
                lng: +selectedMarker?.kiosk_map_x,
              }
        }
        zoom={13}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
        }}
      >
        {kisokGps.map((v: any) => {
          return (
            <>
              <MarkerF
                zIndex={selectedMarker?.kiosk_key === v.kiosk_key ? 10 : 0}
                onClick={() => {
                  setSelectedMarker(v);
                  map?.setCenter({ lat: +v.kiosk_map_y, lng: +v.kiosk_map_x });
                  map?.setZoom(15);
                }}
                position={{ lat: +v.kiosk_map_y, lng: +v.kiosk_map_x }}
                icon={{
                  url:
                    selectedMarker?.kiosk_key === v.kiosk_key
                      ? SelectKioskMarker
                      : KioskMarker,
                  scaledSize: new window.google.maps.Size(55, 70),
                }}
              />
            </>
          );
        })}
      </GoogleMap>
      <div
        style={{
          boxShadow: "16px 11px 60px 27px rgba(0, 0, 0, 0.10)",
        }}
        className="flex flex-row absolute p-[40px] bottom-[100px] bg-white w-[994px] rounded-[10px] "
      >
        <img
          style={{
            width: 255,
            height: 227,
          }}
          src={selectedMarker?.kiosk_img_url}
        ></img>
        <div className="ml-[23px]">
          <div className=" text-[30px] font-bold">
            {selectedMarker?.[`kiosk_name${currentLanguage as "_en"}`]}
          </div>
          <div className=" text-[30px] leading-[48px]">
            {selectedMarker?.[`kiosk_address${currentLanguage as "_en"}`]}
          </div>
          <div className=" text-[30px] leading-[48px] mb-[15px]">
            {string[userLanguage as "EN"].MapOpenTime}
            {"   "}
            {selectedMarker?.kiosk_op_time}
          </div>
          <div className="text-[30px] text-primary">
            KRW{" "}
            <img
              style={{
                display: "inline",
                width: 23,
                height: 17,
              }}
              src={ArrowKiosk}
            />{" "}
            {string[userLanguage as "EN"].MapForiegn}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;

import React from "react";
import { LanguageProps } from "types/Type";
import string from "locales";
import { fontSize36, leading50 } from "const/fontSize";
export default function YellowDiv({ userLanguage }: LanguageProps) {
  return (
    <div className="text-center bg-primary h-[280px] flex justify-center items-center">
      <div className={`${fontSize36} ${leading50}`}>
        {string[userLanguage].InqueryTitle1}
        <br />
        {string[userLanguage].InqueryTitle2}
      </div>
    </div>
  );
}

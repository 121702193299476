import React from "react";
import HomeImg from "asset/image/HomeImage.png";
import Inquery from "asset/image/inquery.png";
import KioskLocationButton from "asset/image/kioskLocationButton.png";
import { Link } from "react-router-dom";
import MoblieHome from "asset/image/MobileHome.png";
import PlayStore from "asset/image/mobile_android_down_icon.png";
import AppStore from "asset/image/mobile_ios_down_icon.png";
import KioskFind from "asset/image/MobileKioskFind.png";
import KioskArrow from "asset/image/KioskArrow.png";
import { LanguageProps } from "types/Type";
import string from "locales";
export default function MobileMainImgComponent({
  userLanguage,
}: LanguageProps) {
  return (
    <div
      className="w-full px-24 flex flex-col justify-center"
      style={{
        backgroundImage: `url(${MoblieHome})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100vh",
      }}
    >
      <div className=" text-white text-20 leading-24   ">
        <div className=" text-20 font-bold leading-24">
          {string[userLanguage].MainmobileTilte1}
        </div>
        <div className=" font-semibold leading-24">
          {string[userLanguage].MainmobileTilte2}
        </div>
        <div className="flex flex-row gap-x-9 my-17">
          <img style={{ width: 92 }} src={AppStore} />
          <img style={{ width: 92 }} src={PlayStore} />
        </div>
        <button
          style={{
            width: 208,
            backgroundImage: `url(${KioskFind})`,
            backgroundSize: "cover",
            height: 42,
            fontSize: 14,
            fontWeight: 500,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {string[userLanguage].MoneyplexFindKiosk}
          <img
            style={{
              width: 20,
              height: 20,
              marginLeft: 10,
            }}
            src={KioskArrow}
          />
        </button>
      </div>
    </div>
  );
}

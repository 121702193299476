export const IMG_SOURE = "./asset/img/";

export const GLOBLE_COLOR = {
  PRIMARY: "#FEC131",
};

export const personObject = {
  KR: [
    {
      person: "32세, 직장인",
      content:
        '"출장 때문에 외화가 필요할 때가 많은데, 은행 영업시간에 맞추기 어려웠어요. 덕분에 언제든지 환전할 수 있어서 정말 편리 했습니다.”',
    },
    {
      person: "29세, 여행객",
      content:
        '"해외여행 전에 환전을 깜빡하고 못 했는데, 공항에서 머니플렉스 키오스크를 발견하고 바로 환전할 수 있었어요. 은행보다 수수료도 저렴해요!"',
    },
    {
      person: "45세, 카지노 매니저",
      content:
        ' "우리 카지노에 머니플렉스 키오스크 설치 이후로 고객들의 환전 불편함이 크게 줄었어요. 24시간 운영이 가능하니 고객들의 만족도가 확실히 높아졌습니다."',
    },
    {
      person: "26, 대학생",
      content:
        '"친구들과 일본 여행을 가기 전에 환율이 제일 좋을 때 머니플렉스 앱으로 환전했어요. 환율 비교도 되고, 모바일 앱으로 간편하게 해결할 수 있었던 점이 좋았어요"',
    },
  ],
  CT: [
    {
      person: "32歲, 上班族",
      content:
        '"因為出差常常需要外幣，但很難配合銀行 營業時間。多虧有了這個服務，現在無論什麼時 候都可以換匯，真的很方便。”',
    },
    {
      person: "29歲, 旅遊者",
      content:
        '"出國旅遊前忘記換匯了，幸好在機場看到了 MoneyPlex 的自助 兌換機，馬上就換好了。而且手續費比銀行 還便宜!"',
    },
    {
      person: "45歲, 賭場經理",
      content:
        '"自從我們賭 場安裝了 MoneyPlex 兌換機，客戶換匯的不 便明顯減少了。24小時運營大大提 高了客戶的滿意度。”',
    },
    {
      person: "26歲, 大學生",
      content:
        '"去日本旅行前，趁匯率最好的 時候用 MoneyPlex App 換了錢。不僅可 以比較匯率，而且可 以用手機 App 方便地解決，真的 很棒！”',
    },
  ],
  JP: [
    {
      person: "32歳, 会社員",
      content:
        '"出張で外貨が必要なこ とが多い のですが、銀行の営業時間に合わ せるのが難しかっ たです。おかげで いつでも両替 ができて本当に 便利でした。”',
    },
    {
      person: "29歳, 旅行者",
      content:
        '"海外旅行の前に 両替を忘れてしまいました が、空港でMoneyPlexのキオスク を見つけてすぐに両替で きました。銀行よりも手 数料が安いです!"',
    },
    {
      person: "45歳, カジノマネージャー",
      content:
        '"私たちのカジノに MoneyPlexの キオスクが  設置されてから、お客様の両 替の不便さが 大幅に減 りました。24時間営業 が可能なので、お客様の 満足 度も確実に向 上しました。”',
    },
    {
      person: "26歳, 大学生",
      content:
        '"日本旅行に 行く前に、為替レートが 一番良い 時にMoneyPlexアプリで両替し ました。為替レートを比較できるし、モバイル アプリで簡単に解決 できたのが良かっ たです。”',
    },
  ],
  EN: [
    {
      person: "32 years old, office worker",
      content:
        '"I often need foreign currency due to business trips, but it was hard to match the banks operating hours. Thanks to this service, I can exchange currency anytime, which is really convenient.”',
    },
    {
      person: "29 years old, traveler",
      content:
        '"I forgot to exchange money before my trip, but I found a MoneyPlex kiosk at the airport and exchanged it right away. The fees are cheaper than at the bank!"',
    },
    {
      person: "45 years old, casino manager",
      content:
        '"Since we installed the MoneyPlex kiosk in our casino, customers inconvenience with currency exchange has significantly decreased. Being able to operate 24 hours has definitely improved customer satisfaction.”',
    },
    {
      person: "26 years old, university student",
      content:
        '"Before going on a trip to Japan, I exchanged money at the best rate using the MoneyPlex app. I could compare rates, and it was convenient to handle everything through the mobile app.”',
    },
  ],
  TH: [
    {
      person: "32 ปี, พนักงานออฟฟิศ",
      content:
        '"ต้องใช้เงินตราต่างประเทศบ่อย ๆ เพราะการเ ดินทางไปทำงาน แต่ยากที่จะ ตรงกับเวลาทำการขอ งธนาคาร ขอบคุณบริการนี้ ที่ทำให้สาม ารถแลกเปลี่ย นเงินได้ตลอดเวลา สะดวก มากจริง ๆ ค่ะ"',
    },
    {
      person: "29 ปี, นักท่องเที่ยว",
      content:
        '"ลืมแล กเงินก่อนการเดิ นทางต่างประเทศ แต่โชคดีที่ พบคีออส MoneyPlex ที่สนามบิ นและแลกเงินได้ทันที ค่าธรรมเ นียมถูกกว่าธนาค ารอีกด้วย!"',
    },
    {
      person: "45 ปี, ผู้จัดการคาสิโน",
      content:
        '"ตั้งแต่ติดตั้งคีออส MoneyPlex ในคาสิโนของเรา ความไม่ส ะดวกในการแลก เปลี่ยนเงินของลูกค้าลดลงอย่าง มาก การให้ บริการตลอด 24 ชั่วโมงทำให้ความพึงพ อใจของลูกค้าเพิ่มขึ้นอ ย่างมากค่ะ"',
    },
    {
      person: "26 ปี, นักศึกษา",
      content:
        '"ก่อนจะไปเที่ยวญี่ปุ่น ฉันแลกเงินในตอน ที่อัตราแลกเปลี่ยนดี ที่สุดผ่านแอป MoneyPlex เปรียบเที ยบอัตราแลกเปลี่ยนได้ และการใช้แอ ปผ่านมือถือก็สะ ดวกมากค่ะ"',
    },
  ],
  CN: [
    {
      person: "32岁, 上班族",
      content:
        '"因为出差 经常需要外币，但很难赶上 银行的营业时间。多亏了这个服务，现在可 以随时换汇，真的 很方便。”',
    },
    {
      person: "29岁, 旅行者",
      content:
        '"出国旅行前忘 记换汇了，幸好在机场看到了MoneyPlex 的自助兑换机，马上就换好了。而且手 续费比银 行还便宜!"',
    },
    {
      person: "45岁, 赌场经理",
      content:
        '"自从我们 赌场安装了MoneyPlex 兑换机，顾客换 汇的不便大大 减少了。24小时运营大 大提高了顾客的满 意度。”',
    },
    {
      person: "26岁, 大学生",
      content:
        '"去日本 旅行之前，我用MoneyPlex App在汇率最好的 时候换了钱。不仅可以 比较汇率，而且可以 用手机App方便地解决，真的 很棒！”',
    },
  ],
};
export const GUIDE_URL = {
  KIOSK_BILL: "kiosk_bill",
  KIOSK_CURRENCY_EXCHANGE: "kiosk_currency_exchange",
  KIOSK_POINT: "kiosk_point",
  KIOSK_CARD_ISSUE: "kiosk_card_issue",
  KIOSK_RECIVE_MONEY: "receive_money",
  APP_RESERVATION: "app_reservation",
  APP_SIGNUP: "app_signup",
  APP_COUPON: "app_coupon",
};

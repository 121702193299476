import React from "react";
import Moneyplex1 from "asset/image/Moneyplex_1.png";
import Moneyplex2 from "asset/image/Moneyplex_2.png";
import Moneyplex3 from "asset/image/Moneyplex_3.png";
import Moneyplex4 from "asset/image/Moneyplex_4.png";

import KioskImg from "asset/image/KioskLeft.png";
import { LanguageProps } from "types/Type";

import string from "locales";
import { fontSize26, fontSize40 } from "const/fontSize";
export default function KioskInfo({ userLanguage }: LanguageProps) {
  return (
    <div>
      <div className=" font-medium text-primary text-36 mb-20">Kiosk</div>

      <div className="flex flex-row gap-x-50 relative">
        <div className={`${fontSize40} font-medium  leading-60`}>
          <div>{string[userLanguage].MoneyplexKioskTitle}</div>
        </div>

        <img className="max-lg:hidden" style={{ height: 300 }} src={KioskImg} />
      </div>
      <div className=" font-bold my-30 2xl:text-30 xl:text-27 lg:text-24 max-md: text-21 max-lg:font-medium">
        {string[userLanguage].MoneyplexExchangeProcess}
      </div>
      <div className="flex flex-col eading-20 lg:gap-y-70 gap-y-20">
        <div className="flex flex-row items-center gap-x-50 max-lg:flex-col w-full gap-y-10">
          <img
            style={{
              objectPosition: "center center",
              objectFit: "contain",
            }}
            src={Moneyplex1}
            className="  md:w-[40%] lg:w-[50%] lg:pr-30 pr-0"
          />
          <div className="w-[50%]  max-lg:w-full">
            <div
              className={`font-bold mb-11 2xl:text-32 xl:text-28 lg:text-24 max-md: text-16`}
            >
              {string[userLanguage].MoneyplexExchange1Title}
            </div>
            <div className=" text-[#696D72] 2xl:text-24 xl:text-20 lg:text-16 max-md:text-14 leading-35">
              {string[userLanguage].MoneyplexExchange1Body}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-50 max-lg:flex-col-reverse w-full gap-y-10">
          <div className="w-[50%] max-lg:w-full">
            <div
              className="font-bold mb-11 2xl:text-32 xl:text-28 lg:text-24 max-md: text-21
"
            >
              {string[userLanguage].MoneyplexExchange2Title}
            </div>
            <div className="text-[#696D72] 2xl:text-24 xl:text-20 lg:text-16 max-md:text-12 leading-35">
              {string[userLanguage].MoneyplexExchange2Body}
            </div>
          </div>
          <img
            src={Moneyplex2}
            className=" md:w-[40%] lg:w-[50%] lg:pl-30 pl-0"
          />
        </div>
        <div className="flex flex-row items-center gap-x-50 max-lg:flex-col w-full gap-y-10">
          <img
            src={Moneyplex3}
            className=" md:w-[40%] lg:w-[50%]  lg:pr-30 pr-0"
          />
          <div className="w-[50%] max-lg:w-full">
            <div className="font-bold mb-11 2xl:text-32 xl:text-28 lg:text-24 max-md: text-21">
              {string[userLanguage].MoneyplexExchange3Title}
            </div>
            <div className="text-[#696D72] 2xl:text-24 xl:text-20 lg:text-16 max-md:text-12 leading-35">
              {string[userLanguage].MoneyplexExchange3Body}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-50 max-lg:flex-col-reverse w-full gap-y-10">
          <div className="w-[50%] max-lg:w-full">
            <div
              className="font-bold  mb-11 2xl:text-32 xl:text-28 lg:text-24 max-md:text-21
"
            >
              {string[userLanguage].MoneyplexExchange4Title}
            </div>
            <div className=" text-[#696D72] 2xl:text-24 xl:text-20 lg:text-16 max-md:text-12 leading-35">
              {string[userLanguage].MoneyplexExchange4Body}
            </div>
          </div>
          <img
            src={Moneyplex4}
            className=" md:w-[40%] lg:w-[50%] lg:pl-30 p-0"
          />
        </div>
      </div>
    </div>
  );
}

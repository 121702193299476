import React from "react";
import YellowDiv from "./component/YellowDiv";
import InqeryForm from "./component/InqeryForm";
import { useOutletContext } from "react-router-dom";
import { LanguageCode } from "types/Type";
export default function Inquery() {
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  return (
    <div>
      <YellowDiv userLanguage={userLanguage} />
      <InqeryForm userLanguage={userLanguage} />
    </div>
  );
}

import React from "react";
import { IMG_SOURE } from "../../const/const";
import Logo from "asset/image/logo.png";
import BlogIcon from "asset/image/blog_icon.png";
import InstaIcon from "asset/image/insta_icon.png";
import faceBookIcon from "asset/image/facebook_icon.png";
import isMobile from "util/VaildMobile";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
export default function Footer() {
  const mobile = useWindowSizeCustom();

  return mobile.width < 750 ? (
    <div className="flex  bg-[#F0F2F4] justify-center py-20 px-24 text-12">
      <div className=" flex flex-col gap-y-[10px] font-medium leading-14 ">
        <span className=" inline-block">
          (주)코인트래빗 <span className=" mx-4 text-[#969696]">|</span> 대표:
          이종선
        </span>
        고객센터: +82-1833-4283 (평일오전 10:00 ~ 오후 18:00)
        <div>이용문의: support@moneyplex.net</div>
        <div>사업자 등록번호: 872-88-00742</div>
        <div>일반, 온라인 환전업 및 무인환전업 정식등록 제131A00690호 </div>
        <div> 벤처기업 인증 제 20200107270호</div>
        <div>
          주소: 경기도 성남시 대왕판교로 815, 기업지원허브 혁신기술존 4층 463호
        </div>
        <div className="my-20">
          <div className="font-bold">
            서비스 이용약관 | 개인정보 처리방침 | 오픈뱅킹 약관 | 마케팅 정보
            수신동의
          </div>
          <div className=" font-bold mt-10">
            고객 민원 및 손해배상 처리 절차
          </div>
        </div>
        <div className="mb-20 flex flex-row gap-x-[34px]">
          <img src={BlogIcon} width={26} height={26} />
          <img src={InstaIcon} width={26} height={26} />
          <img src={faceBookIcon} width={26} height={26} />
        </div>
        <div className="w-full h-[1px] bg-[#e1e1e1]"></div>
        <div className="text-center mt-14 text-10">
          Copyright ©Cointravit Corp.
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="bg-[#F0F2F4] py-40 px-[20%] font-medium">
        <div className="flex">
          <div className="w-full flex flex-col gap-y-10 text-14 leading-20">
            <img width={140} height={34} src={Logo} />
            <div className="">
              (주)코인트래빗 <span className="px-[10px]">|</span> 대표: 이종선{" "}
              <span className="px-[10px]">|</span>
              고객센터: +82-1833-4283 (평일오전 10:00 ~ 오후 18:00)
            </div>
            <div>
              이용문의: support@moneyplex.net{" "}
              <span className="px-[10px]">|</span>
              사업자 등록번호: 872-88-00742
            </div>
            <div>
              일반, 온라인 환전업 및 무인환전업 정식등록 제131A00690호{" "}
              <span className="px-[10px]">|</span> 벤처기업 인증 제
              20200107270호
            </div>
            <div>
              주소: 경기도 성남시 대왕판교로 815, 기업지원허브 혁신기술존 4층
              463호
            </div>
            <div className="mt-20 font-bold">
              서비스 이용약관
              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              개인정보
              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              처리방침
              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              오픈뱅킹 약관
              <span className="font-medium text-[#969696] text-14 mx-10">
                |
              </span>
              마케팅 정보 수신동의
            </div>
          </div>
          <div className="flex items-end">
            <div className="flex flex-row gap-x-[34px]">
              <img
                style={{
                  objectFit: "contain",
                }}
                src={BlogIcon}
                width={35}
              />
              <img
                style={{
                  objectFit: "contain",
                }}
                src={InstaIcon}
                width={35}
              />
              <img
                style={{
                  objectFit: "contain",
                }}
                src={faceBookIcon}
                width={35}
              />
            </div>
          </div>
        </div>

        <div className="h-1 bg-[#e1e1e1] w-[calc(100%+100px)] mt-20 mb-40">
          <div className="pt-20 block">Copyright ©Cointravit Corp.</div>
        </div>
      </div>
    </>
  );
}

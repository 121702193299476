import React from "react";
import AppScreen from "asset/image/appScreen.png";

import AppImage1 from "asset/image/AppImage1.png";
import AppImage2 from "asset/image/AppImage2.png";
import AppImage3 from "asset/image/AppImage3.png";
import AppImage4 from "asset/image/AppImage4.png";
import { fontSize32, fontSize24, fontSize36, fontSize40 } from "const/fontSize";
import string from "locales";
import { LanguageProps } from "types/Type";

export default function AppInfo({ userLanguage }: LanguageProps) {
  return (
    <div>
      <div className="py-70 flex flex-col px-25 bg-[#F4F7FB] gap-y-10 lg:px-[20%] lg:gap-y-80">
        <div className="">
          <div className={`${fontSize36} text-primary font-bold mb-30`}>
            App
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className={`${fontSize40} font-bold leading-60 lg:mr-[50px]`}>
              {string[userLanguage].MoneyplexAppTitle}
            </div>
            <img
              style={{
                objectFit: "contain",
              }}
              className="w-[30%] hidden lg:block"
              src={AppScreen}
            ></img>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-50 lg:flex-row">
          <img
            style={{
              objectFit: "contain",
            }}
            className="md:w-[50%]"
            src={AppImage1}
          />
          <div className="flex  flex-col  justify-center">
            <div className={`${fontSize32}  font-bold my-11`}>
              {string[userLanguage].MoneyplexApp1Title}
            </div>
            <div className={`${fontSize24} leading-35 text-[#696D72]`}>
              {string[userLanguage].MoneyplexApp1Body}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-x-50 lg:flex-row">
          <img
            style={{
              objectFit: "contain",
            }}
            className="md:w-[50%]"
            src={AppImage2}
          />
          <div className="flex flex-col justify-center">
            <div className={`${fontSize32}  font-bold my-11`}>
              {string[userLanguage].MoneyplexApp2Title}
            </div>
            <div className={`${fontSize24} leading-35 text-[#696D72]`}>
              {string[userLanguage].MoneyplexApp2Body}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-x-50 lg:flex-row">
          <img
            style={{
              objectFit: "contain",
            }}
            className="md:w-[50%]"
            src={AppImage3}
          />
          <div className="flex flex-col justify-center">
            <div className={`${fontSize32}  font-bold my-11`}>
              {string[userLanguage].MoneyplexApp3Title}
            </div>
            <div className={`${fontSize24} leading-35 text-[#696D72]`}>
              {string[userLanguage].MoneyplexApp3Body}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-x-50 lg:flex-row">
          <img
            style={{
              objectFit: "contain",
            }}
            className="md:w-[50%]"
            src={AppImage4}
          />
          <div className="flex flex-col justify-center">
            <div className={`${fontSize32}  font-bold my-11`}>
              {string[userLanguage].MoneyplexApp4Title}
            </div>
            <div className={`${fontSize24} leading-35 text-[#696D72]`}>
              {string[userLanguage].MoneyplexApp4Body}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

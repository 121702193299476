import React from "react";
import AppScreen from "asset/image/appScreen.png";
import KioskScreen from "asset/image/kioskImg.png";
import { fontSize36, leading50 } from "const/fontSize";
import KioskImg from "asset/image/KioskLeft.png";
import Train from "asset/image/Train.png";
import Card from "asset/image/MobileCard.png";
import string from "locales";
import { LanguageCode, LanguageProps } from "types/Type";
export default function MobileAppKiosk({ userLanguage }: LanguageProps) {
  return (
    <>
      <div
        className={`${leading50} flex relative bg-[#F8FcFF] w-full  pb-[17%] pt-[10%]`}
      >
        <div className="flex flex-col pl-[15%] mb-30">
          <div className={`${fontSize36} text-primary font-medium`}>
            {string[userLanguage].MaincardSectionTitle}
          </div>
          <div className={`${fontSize36}  font-medium`}>
            {string[userLanguage].MaincardSectionBody1}
          </div>
          <div className={`${fontSize36} font-medium`}>
            {string[userLanguage].MaincardSectionBody2}
          </div>
          <div className={`${fontSize36} font-medium`}>
            {string[userLanguage].MaincardSectionBody3}
          </div>
          <div className={`${fontSize36} font-medium text-borederA2`}>
            {string[userLanguage].MaincardSectionBody4}
          </div>
        </div>
        <img className=" absolute bottom-0 object-fill" src={Train} />

        <img
          className="z-10 absolute bottom-0 right-100"
          width={"25%"}
          src={Card}
        />
      </div>

      <div className=" flex justify-center my-50">
        <div className="flex flex-col justify-center items-center px-[15%] py-[1%]">
          <div
            className={`${fontSize36} w-full flex flex-col justify-center ${leading50}`}
          >
            <div className={` text-primary font-bold`}>APP</div>
            <span className=" font-medium">
              {string[userLanguage].MainappSectionTitle}
            </span>
            <span className="text-[#A2AAB4] inline-block  font-medium ">
              {string[userLanguage].MainappSectionSubBody}
            </span>
          </div>
          <div className="flex w-full mt-[8%] items-end justify-end">
            <img
              style={{
                width: "50%",
              }}
              src={AppScreen}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center text-right bg-[#F8FcFF] px-[15%] ">
        <div
          className={`w-full  ${fontSize36} pt-30  flex flex-col gap-y-[10px] justify-center ${leading50} mb-30`}
        >
          <div className={` text-primary font-bold `}>KIOSK</div>
          <span style={{}} className=" font-medium">
            {string[userLanguage].MainkioskSectionTitle}
          </span>

          <span className=" text-[#A2AAB4]">
            {string[userLanguage].MainkioskSectionBody}
          </span>
        </div>
        <div className="w-full">
          <img
            style={{
              objectFit: "fill",
              height: "100%",
            }}
            src={KioskImg}
          />
        </div>
      </div>
    </>
  );
}

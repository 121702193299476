import React, { useEffect, useRef, useState } from "react";
import MainImgComponent from "./component/MainImgComponent";
import MobileAppKioskInfo from "./component/mobile/M_AppKioskInfo";
import MobileMainKioskImg from "./component/mobile/M_MainKioskImg";
import MobileMainImgComponent from "./component/mobile/M_MainImgComponent";
import MobilePartnerShip from "./component/mobile/M_PartnerShip";
import AppKioskInfo from "./component/AppKioskInfo";
import PartnerShip from "./component/PartnerShip";
import MainKioskImg from "./component/MainKioskImg";
import IntroductionPoint from "./component/IntroductionPoint";
import IntroductionInquiry from "./component/IntroductionInquiry";
import VaildMobile from "util/VaildMobile";
import MKioskLocation from "./component/mobile/M_KioskLocation";
import useWindowSizeCustom from "hooks/useWindowSizeCustom";
import useUserLanguage from "hooks/useUserLanguage";
import { useOutletContext } from "react-router-dom";
import { LanguageCode } from "types/Type";
import MobileAppKiosk from "./component/mobile/M_AppKioskInfo";
const TOTAL_SECTIONS = 7;
export default function Home() {
  const isMoblie = useWindowSizeCustom();
  const { userLanguage: language } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const HEADER_HEIGHT = isMoblie.width < 900 ? 50 : 75;

  const [activeSection, setActiveSection] = useState<number>(0);
  const sections = useRef<(HTMLElement | null)[]>([]);
  const scrolling = useRef<boolean>(false);
  const scrollToSection = (index: number): void => {
    const targetSection = sections.current[index];
    if (!targetSection) return;

    const targetPosition = targetSection.offsetTop - HEADER_HEIGHT;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = (): void => {
      if (scrolling.current) return;

      const currentScrollPosition = window.scrollY + HEADER_HEIGHT;
      let newActiveSection = 0;

      sections.current.forEach((section, index) => {
        if (!section) return;

        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (
          currentScrollPosition >= sectionTop - window.innerHeight / 3 &&
          currentScrollPosition < sectionBottom - window.innerHeight / 3
        ) {
          newActiveSection = index;
        }
      });

      setActiveSection(newActiveSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleWheel = (e: WheelEvent): void => {
      e.preventDefault();

      if (scrolling.current) return;

      scrolling.current = true;

      if (e.deltaY > 0 && activeSection < TOTAL_SECTIONS - 1) {
        scrollToSection(activeSection + 1);
        setActiveSection((prev) => prev + 1);
      } else if (e.deltaY < 0 && activeSection > 0) {
        scrollToSection(activeSection - 1);
        setActiveSection((prev) => prev - 1);
      }

      setTimeout(() => {
        scrolling.current = false;
      }, 1000);
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => window.removeEventListener("wheel", handleWheel);
  }, [activeSection]);

  return (
    <>
      {isMoblie.width < 600 ? (
        <>
          <MobileMainImgComponent userLanguage={language} />
          <MobileAppKiosk userLanguage={language} />
          <MobilePartnerShip userLanguage={language} />
          <MKioskLocation userLanguage={language} />
        </>
      ) : (
        <div className="min-w-[750px]">
          <div ref={(el) => (sections.current[0] = el)}>
            <MainImgComponent userLanguage={language} />
          </div>
          <div>
            <AppKioskInfo userLanguage={language} sectionRef={sections} />
          </div>
          <div ref={(el) => (sections.current[4] = el)}>
            <PartnerShip userLanguage={language} />
          </div>
          <div ref={(el) => (sections.current[5] = el)}>
            <MainKioskImg userLanguage={language} />
          </div>
          <div ref={(el) => (sections.current[6] = el)}></div>
        </div>
      )}
    </>
  );
}

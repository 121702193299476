import React from "react";
import { GLOBLE_COLOR, IMG_SOURE } from "../../../const/const";
import AppScreen from "asset/image/appScreen.png";
import KioskScreen from "asset/image/kioskImg.png";
import { fontSize36, leading50 } from "const/fontSize";
import KioskImg from "asset/image/KioskImage5.png";
import Train from "asset/image/Train.png";
import Card from "asset/image/MobileCard.png";
import string from "locales";
import { LanguageCode, LanguageProps } from "types/Type";

export default function AppKioskInfo({
  userLanguage,
  sectionRef,
}: {
  userLanguage: LanguageCode;
  sectionRef: any;
}) {
  return (
    <>
      <div
        ref={(el) => (sectionRef.current[1] = el)}
        className={`leading-50 h-[calc(100vh-75px)] flex relative bg-[#F8FcFF] w-full  pb-[17%] pt-[10%]`}
      >
        <div className="flex flex-col pl-[20%] mb-30">
          <div className={`text-36 text-primary font-medium`}>
            {string[userLanguage].MaincardSectionTitle}
          </div>
          <div className={`text-36 font-medium`}>
            {string[userLanguage].MaincardSectionBody1}
          </div>
          <div className={`text-36 font-medium`}>
            {string[userLanguage].MaincardSectionBody2}
          </div>
          <div className={`text-36 font-medium`}>
            {string[userLanguage].MaincardSectionBody3}
          </div>
          <div className={`text-36 font-medium text-borederA2`}>
            {string[userLanguage].MaincardSectionBody4}
          </div>
        </div>
        <img className=" absolute bottom-0 object-fill" src={Train} />

        <img
          className="z-10 absolute bottom-0 right-[20%]"
          width={"25%"}
          src={Card}
        />
      </div>

      <div
        ref={(el) => (sectionRef.current[2] = el)}
        className="h-[calc(100vh-75px)] flex justify-center my-50"
      >
        <div className=" relative flex justify-center items-center px-[20%] py-[1%]">
          <div
            className={`${fontSize36} w-[80%] flex flex-col justify-center ${leading50}`}
          >
            <div className={` text-primary font-bold`}>APP</div>
            <span className=" font-medium">
              {string[userLanguage].MainappSectionTitle}
            </span>
            <span className="text-[#A2AAB4] inline-block  font-medium ">
              {string[userLanguage].MainappSectionSubBody}
            </span>
          </div>
          <div className="flex w-full mt-[8%] relative right-[-30px] items-end justify-end">
            <img
              style={{
                width: "80%",
              }}
              src={AppScreen}
            />
          </div>
        </div>
      </div>
      <div
        ref={(el) => (sectionRef.current[3] = el)}
        className="flex h-[calc(100vh-75px)] relative overflow-hidden justify-center text-right bg-[#F8FcFF] px-[20%] "
      >
        <div className="w-[50%]  relative left-[-30px] overflow-hidden ">
          <img
            style={{
              objectFit: "contain",
              height: "100vh",
              objectPosition: "center center",
            }}
            src={KioskImg}
          />
        </div>
        <div
          className={`w-[50%]  ${fontSize36} pt-30  flex flex-col gap-y-[10px] justify-center ${leading50} mb-30`}
        >
          <div className={` text-primary font-bold `}>KIOSK</div>
          <span style={{}} className=" font-medium">
            {string[userLanguage].MainkioskSectionTitle}
          </span>

          <span className=" text-[#A2AAB4] font-medium">
            {string[userLanguage].MainkioskSectionBody}
          </span>
        </div>
      </div>
    </>
  );
}

export default function TabButton({
  currentTab,
  setCurrentTab,
  leftText,
  rightText,
}: {
  currentTab: string;
  setCurrentTab: any;
  leftText: string;
  rightText: string;
}) {
  return (
    <div className="flex flex-row items-center justify-center">
      <div className="flex flex-row w-[600px] items-center justify-center h-[75px] bg-[#3F3F3F] rounded-[8px] bg-opacity-15 text-[24px] text-[#3F3F3F] font-medium">
        <div
          onClick={() => {
            setCurrentTab(leftText);
          }}
          style={{
            backgroundColor: currentTab === leftText ? "#FFFFFF" : "",

            boxShadow:
              currentTab === leftText
                ? "0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04)"
                : "",
          }}
          className="ml-[2px] flex justify-center text-[#000]  w-[50%] h-[73px]  items-center text-center  rounded-[8px]"
        >
          {leftText}
        </div>
        <div
          style={{
            backgroundColor: currentTab === rightText ? "#FFFFFF" : "",
            boxShadow:
              currentTab === rightText
                ? "0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04)"
                : "",
          }}
          onClick={() => {
            setCurrentTab(rightText);
          }}
          className="flex justify-center w-[50%] h-[73px] mt-[2px] items-center text-center text-[#000] rounded-[8px] mr-[2px]"
        >
          {rightText}
        </div>
      </div>
    </div>
  );
}

import TabButton from "component/common/TabButton";
import React, { useState } from "react";
import AppChangeMoney from "asset/image/Guide_App_Change_Money.png";
import AppSignUp from "asset/image/Guide_APP_SignUp.png";
import AppCoupon from "asset/image/Guide_APP_Coupon.png";
import AppSetting from "asset/image/Guide_App_Setting.png";
import KioskBillImg from "asset/image/KioskBillIMG.png";
import KioskPointImg from "asset/image/KioskPointIMG.png";
import KioskCurrencyImg from "asset/image/KioskCurrencyIMG.png";
import KioskCardImg from "asset/image/KioskCardIMG.png";
import ReciveMoneyImg from "asset/image/revicemoney.png";
import { Link, useOutletContext } from "react-router-dom";
import { GUIDE_URL } from "const/const";
import { LanguageCode } from "types/Type";
import string from "locales";
import { fontSize32 } from "const/fontSize";
export default function Guide() {
  const [currentTab, setCurrentTab] = useState("KIOSK");
  const { userLanguage } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  return (
    <div className="mb-100">
      <div className="pt-93 mb-140 px-30 ">
        <TabButton
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          leftText={"KIOSK"}
          rightText={"APP"}
        />
      </div>
      <div className=" grid grid-cols-1  px-[18%] gap-y-50 mb-50 gap-x-30 md:grid-cols-2 lg:grid-cols-3">
        {currentTab === "KIOSK" ? (
          <>
            <Link to={GUIDE_URL.KIOSK_BILL}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskBillImg})`,
                }}
              >
                {string[userLanguage].GuideKioskBill}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_CURRENCY_EXCHANGE}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskCurrencyImg})`,
                }}
              >
                {string[userLanguage].GuideKioskCurrencyExchange}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_POINT}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskPointImg})`,
                }}
              >
                {string[userLanguage].GuideKioskPoint}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_CARD_ISSUE}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${KioskCardImg})`,
                }}
              >
                {string[userLanguage].GuideKioskCardIssue}
              </div>
            </Link>
            <Link to={GUIDE_URL.KIOSK_RECIVE_MONEY}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${ReciveMoneyImg})`,
                }}
              >
                {string[userLanguage].GuideKioskRecive}
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link to={GUIDE_URL.APP_RESERVATION}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppChangeMoney})`,
                }}
              >
                {string[userLanguage].GuideAppReservation}
              </div>
            </Link>
            <Link to={GUIDE_URL.APP_SIGNUP}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppSignUp})`,
                }}
              >
                {string[userLanguage].GuideAppSignUp}
              </div>
            </Link>
            <Link to={GUIDE_URL.APP_COUPON}>
              <div
                className={`px-35 py-44 text-32 font-bold ${fontSize32}`}
                style={{
                  height: 311,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${AppCoupon})`,
                }}
              >
                {string[userLanguage].GuideAppCoupon}
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

import React from "react";
import EventImg from "asset/image/eventExample.png";
import ShareIcon from "asset/image/ShareIcon.png";
import { Link, useOutletContext } from "react-router-dom";
import VKIMG_KR from "asset/image/event_EN.png";
import VKIMG_EN from "asset/image/event_EN.png";
import VKIMG_CN from "asset/image/event_CN.png";
import VKIMG_CT from "asset/image/event_CN.png";
import VKIMG_TH from "asset/image/event_EN.png";
import VKIMG_JP from "asset/image/event_JP.png";
import { LanguageCode } from "types/Type";
import string from "locales";
export default function EventDetailPage() {
  const { userLanguage: language } = useOutletContext<{
    userLanguage: LanguageCode;
  }>();
  const selectImg = () => {
    if (language === "KR") return VKIMG_KR;
    if (language === "EN") return VKIMG_EN;
    if (language === "CN") return VKIMG_CN;
    if (language === "CT") return VKIMG_CT;
    if (language === "JP") return VKIMG_JP;
    if (language === "TH") return VKIMG_TH;
  };

  return (
    <div className="flex flex-col items-center justify-center mb-100 mt-150">
      <div className="flex relative mb-30 ">
        <div className="text-36 font-bold">
          {string[language].discountEvent}
        </div>
      </div>
      <img src={selectImg()} width={600}></img>

      <Link to={"/event"}>
        <div className=" bg-primary p-20 w-[600px] rounded-10 text-center text-30 text-white font-bold mt-50">
          {string[language].gotoList}
        </div>
      </Link>
    </div>
  );
}

export const MainPageJP = {
  MaintitleBody1: "プリペイド交通カードと一緒に",
  MaintitleBody2: "外貨両替マネープレックス！",
  MaintitleSubBody: "スマートで簡単な両替サービスを今すぐ体験してください",
  MaincardSectionTitle: "プリペイド交通カード発行",
  MaincardSectionBody1: "マネープレックスで発行し、",
  MaincardSectionBody2: "公共交通からショッピングまで、",
  MaincardSectionBody3: "どこでも簡単で便利な決済",
  MaincardSectionBody4: "外国人も利用可能",
  MainappSectionTitle:
    "迅速で簡 単な両替予約と様々 な提携先クーポン特 典まで！",
  MainappSectionSubBody: "両替後の小銭まで管 理するマネープレ ックス",
  MainkioskSectionTitle:
    "プリペイ ド交通カード発行と チャージが可能 なキオスク",
  MainkioskSectionBody:
    "100種類の通貨、6カ国語に 対応し、プリペイド交通カードの発 行とチャージ、免税 店やデパート、ホテルの メンバーシッ プカードまで対応するマル チキオスク",
  MainpartnerSectionTitle: "パートナー企業",
  MainpartnerSectionBody1: '"ホテルと免税店に簡単な両替キオスクを提供し、',
  MainpartnerSectionBody2: '収益を増加させ、運営はすべて当社が担当します。"',
  MainpartnerSectionInqueryButton: "導入のお問い合わせ",
  MainmobileTilte1: "両替の新しい基準、マネーフレックス！",
  MainmobileTilte2: "現場で直接ご確認ください。",
};

export const MoneyPlexJP = {
  MoneyplexTabLeft: "マネープ レックス顧客",
  MoneyplexTabRight: "ビジネス のお問い合わせ",
  MoneyplexKioskTitle:
    "まだ両替のため にあちこち探して いませんか？今 すぐマネープレック ス両替キオスクをご 利用ください",
  MoneyplexExchangeProcess: "両替プロセス",
  MoneyplexExchange1Title: "ステップ1：両替する外貨を選択",
  MoneyplexExchange1Body:
    "様々な外貨に対応しており、米ドル（USD）、ユーロ（EUR）、日本円（JPY）、中国元（CNY）など、主要15カ国の通貨を選択できます。",
  MoneyplexExchange2Title: "ステップ2：身分証明書（パスポート）をスキャン",
  MoneyplexExchange2Body:
    "身分証明書（パスポート）の情報は必須で確認され、これは韓国での外貨両替の基本手続きです。",
  MoneyplexExchange3Title: "ステップ3：外貨紙幣を投入",
  MoneyplexExchange3Body:
    "選択した外貨をキオスクに投入します。投入された紙幣はキオスクで自動的に認識され、両替金額が計算されます。",
  MoneyplexExchange4Title: "ステップ4：ウォンを受け取る",
  MoneyplexExchange4Body:
    "両替が完了すると、キオスクからウォンを受け取ることができます。両替金額はすぐに現金で支払われ、領収書が印刷されます。",
  MoneyplexAppTitle:
    "MoneyPlex APPは、両替からポイント決済ま で多様な機能を提 供します。モバイルアプ リ1つでい つでもどこでも 簡単に両替 を利用できます。",
  MoneyplexApp1Title: "キオスクの位置検索",
  MoneyplexApp1Body:
    "MoneyPlexアプリ を使って、近くの両替キ オスクの位置をリア ルタイムで確認できま す。周辺のキオスクを見つけ て迅速に両替がで きるようサポートします。",
  MoneyplexApp2Title: "MoneyPlexメンバーシップ",
  MoneyplexApp2Body:
    "MoneyPlex APPのユーザーは、簡単なメンバー シップ認証を通じて様々な 特典を享受でき ます。メンバーシップ認証を完了 すると、ポイントの貯蓄や 両替予約時 の追加割引を受け られます。(韓国人限定)",
  MoneyplexApp3Title: "MoneyPlex両替予約(韓国人限定)",
  MoneyplexApp3Body:
    "韓国人を 対象にしたMoneyPlexアプリ の両替予約機能を使 えば、お得なレートで事前に外 貨両替を予約できます。希望す るレートの時点で予約 して、便利に両替を 完了させてください。",
  MoneyplexApp4Title: "ポイントショップクーポン（韓国人限定）",
  MoneyplexApp4Body:
    "MoneyPlexメンバーのためのポイン トショップクーポン購 入機能を通じて、貯めたポ イントで提携企業のクーポンを購入できます。両替とともに ポイントを貯めて、多様な特 典をお楽しみくだ さい。",
  MoneyplexBusinessTitle: "国内最高の無人両替キオスクソリューション",
  MoneyplexBusinessBody:
    "24時間運営、コスト削減、顧客満足度 向上など、パート ナー企業の成功を支援 する最適なソリューション を提供します。",
  MoneyplexFindKiosk: "キオスクの位置を探す",
  MoneyplexBenefitTitle1: "24時間運営可能",
  MoneyplexBenefitBody1:
    "お客様が時間 を気にせず両替でき、サービス提 供時間を延長し、顧客満足度を 最大化できます。",
  MoneyplexBenefitTitle2: "プリペイド交通カード＆提携店舗カードサポート",
  MoneyplexBenefitBody2:
    "プリペイド交通カードだけで なく、会員専用カードも発 行可能なので、顧客は選択の幅が 広がります。",
  MoneyplexBenefitTitle3: "顧客満足度の向上",
  MoneyplexBenefitBody3:
    "特に外国人観 光客が多いホテル、カジノ、大型 スーパーのような場所では、キオ スクを通じて顧客の利便性 を最大化できます。",
  MoneyplexBenefitTitle4: "簡単な設置とメンテナンス",
  MoneyplexBenefitBody4:
    "設置が 簡単で、メンテナン スも最小限に抑えられ、パートナー企業が複 雑な管理手続 きを取らずに簡単に 運営できます。",
  MoneyplexBenefitTitle5: "収益創出の機会",
  MoneyplexBenefitBody5:
    "キオスクを設置することで、機器からの収益 だけでなく、顧客の増加による追加 収益も期待できます。",
  MoneyplexPartnerBenefitHeader: "パートナー特典",
  MoneyplexPartnerBenefitHeader2:
    "マネープレックスキオスクの設置状況とパートナー特典",
  MoneyplexPartnerBenefitTitle1: "顧客満足度の向上",
  MoneyplexPartnerBenefitBody1: "24時間両替サービスの提供",
  MoneyplexPartnerBenefitTitle2: "コスト削減",
  MoneyplexPartnerBenefitBody2: "人件費0円、完全自動化された運営システム",
  MoneyplexPartnerBenefitTitle3: "追加収益創出",
  MoneyplexPartnerBenefitBody3: "顧客の増加による追加売上",
  MoneyplexPartnerBenefitTitle4: "簡単な管理",
  MoneyplexPartnerBenefitBody4:
    "運営管理システムでリアルタイムの障害モニタリング",
  MoneyplexKioskExampleTitle: "キオスク設置事例",
  MoneyplexKioskExamplelocation1: "ホテル • カジノ",
  MoneyplexKioskExamplebody1:
    "ソウルの主要 なホテルおよびカ ジノに設置さ れています。",
  MoneyplexKioskExamplelocation2: "港 • 鉄道駅",
  MoneyplexKioskExamplebody2: "クルーズ港、ソウル駅 などに設置され ています。",
  MoneyplexKioskExamplelocation3: "デパート • 大型スーパー",
  MoneyplexKioskExamplebody3: "全国 の大型スーパー23か所 に設置されています。",
  MoneyplexCustmerReview: "お客様のレビュー",
  MoneyplexCustmerTitle: "実際のユーザー レビューと成功事 例",
};
export const GuidePageJP = {
  GuideKioskBill: "対応通貨",
  GuideKioskCurrencyExchange: "外貨両替",
  GuideKioskPoint: "ポイント積立",
  GuideKioskPointSub: "アプリ積立/バーコード積立",
  GuideKioskCardIssue: "プリペイドカード発行・チャージ",
  GuideKioskRecive: "受け取り方法",
  GuideAppReservation: "外貨両替予約",
  GuideAppSignUp: "会員登録",
  GuideAppCoupon: "クーポンをダウンロード",
  GuideDetailAppConponText1: "01 下部クーポンタブ",
  GuideDetailAppConponText2: "02 クーポンコード入力",
  GuideDetailAppConponText3: "03 クーポン登録完了",
  GuideDetailAppConponText4: "04 クーポン利用可能",
  GuideDetailAppReservation1: "01 外貨両替予約",
  GuideDetailAppReservation2: "02 支店選択",
  GuideDetailAppReservation3: "03 両替日選択",
  GuideDetailAppReservation4: "04 金額・通貨選択",
  GuideDetailAppReservation5: "05 両替予約申請",
  GuideDetailAppReservation6: "06 予約申請完了",
  GuideDetailAppReservation7: "07 予約管理",
  GuideDetailAppReservation8: "08 入金完了",
  GuideDetailAppReservation9: "09 キオスク受け取り",
  GuideDetailKioskBill1: "01 パスポートスキャン",
  GuideDetailKioskBill2: "02 対応通貨",
  GuideDetailKioskCurrencyExchange1: "01 外貨選択",
  GuideDetailKioskCurrencyExchange2: "02 対応通貨確認",
  GuideDetailKioskCurrencyExchange3: "03 紙幣投入",
  GuideDetailKioskCurrencyExchange4: "04 紙幣金額確認",
  GuideDetailKioskPointSubTitle1: "アプリ積立 - MoneyPlex モバイル充電",
  GuideDetailKioskPointSubTitle2: "KBウォレット",
  GuideDetailKioskPointSubTitle3: "お釣り受取",
  GuideDetailKioskPointSubTitle4: "寄付する",
  GuideDetailKioskPointApp1: "MoneyPlex モバイル充電選択",
  GuideDetailKioskPointApp2: "メンバー選択",
  GuideDetailKioskPointApp3: "メンバー - バーコード",
  GuideDetailKioskPointApp4: "非メンバー - 電話番号入力",
  GuideDetailKioskPointApp5: "非メンバー - 電話番号入力",
  GuideDetailKioskPointApp6: "充電完了",
  GuideDetailKioskKb1: "国民財布の選択",
  GuideDetailKioskKb2: "同意する",
  GuideDetailKioskKb3: "Kb国民財布",
  GuideDetailKioskKb4: "国民財布の選択",
  GuideDetailKioskKb5: "バーコードタグ付け",
  GuideDetailKioskKb6: "充電完了",
  GuideDetailKioskCoin1: "ウォン受け取り選択",
  GuideDetailKioskCoin2: "お釣り受け取り選択",
  GuideDetailKioskCoin3: "メンバー選択",
  GuideDetailKioskCoin4: "メンバー - バーコード",
  GuideDetailKioskCoin5: "非メンバー - 電話番号入力",
  GuideDetailKioskCoin6: "非メンバー - 電話番号入力",
  GuideDetailKioskCoin7: "充電完了",
  GuideDetailKioskReturn1: "ウォン受け取り選択",
  GuideDetailKioskReturn2: "寄付選択",
  GuideDetailKioskReturn3: "寄付完了",
  GuideDetailCardInfo: "プリペイドカードはklookで事前に申請して発行できます。",
  GuideDetailSignUp1: "01 会員登録",
  GuideDetailSignUp2: "02 同意する",
  GuideDetailSignUp3: "03 メール認証",
  GuideDetailSignUp4: "04 パスワード設定",
  GuideDetailSignUp5: "05 セキュリティパスワード設定",
  GuideDetailSignUp6: "06 会員登録完了",

  GuideDetailReceiveMoneyTitle1: "日本円受取",
  GuideDetailReciveMoneyTitle2: "머니플렉스 앱 충전",

  GuideDetailReceiveMoneySubTitle1: "お釣り受取",
  GuideDetailReceiveMoney1_1: "01 韓国ウォン受取選択",
  GuideDetailReceiveMoney1_2: "02 お釣り受取方法選択",
  GuideDetailReceiveMoney1_3: "03 会員/非会員選択",
  GuideDetailReceiveMoney1_4: "04 番号入力/バーコードスキャン",
  GuideDetailReceiveMoney1_5: "05 韓国ウォン受取",
  GuideDetailReceiveMoney1_6: "06 完了",

  GuideDetailReceiveMoneySubTitle2: "寄付（お釣り）",
  GuideDetailReceiveMoney2_1: "01 日本円受取を選択",
  GuideDetailReceiveMoney2_2: "02 お釣り受取を選択",
  GuideDetailReceiveMoney2_3: "03 寄付を選択",
  GuideDetailReceiveMoney2_4: "04 完了",

  GuideDetailReceiveMoneySubTitle3: "マネーフレックスアプリチャージ",
  GuideDetailReceiveMoney3_1: "01 交通カードチャージ",
  GuideDetailReceiveMoney3_2: "02 会員または非会員を選択",
  GuideDetailReceiveMoney3_3: "03 非会員 - 番号入力",
  GuideDetailReceiveMoney3_4: "04 会員 - バーコード入力",
  GuideDetailReceiveMoney3_5: "05 完了",

  GuideDetailReceiveMoneySubTitle4: "KB国民財布",
  GuideDetailReceiveMoney4_1: "01 KB国民財布を選択",
  GuideDetailReceiveMoney4_2: "02 個人情報同意",
  GuideDetailReceiveMoney4_3: "03 バーコードスキャン",
  GuideDetailReceiveMoney4_4: "04 チャージ完了",

  GuideDetailReceiveMoneySubTitle5: "寄付する (全額寄付する)",
  GuideDetailReceiveMoney5_1: "01 寄付するを選択",
  GuideDetailReceiveMoney5_2: "02 寄付完了",

  GuideToolTip1: "下部のクーポンタブで登録してください。\n(外国人対象)",
  GuideToolTip2: "ログイン時に会員パスワードの設定が必要です。",
  GuideToolTip3:
    "セキュリティパスワードは6桁の設定が必要です。\n(アプリ使用およびポイント使用時)",
  GuideToolTip4: "3営業日後の日付から選択可能",
  GuideToolTip5:
    "換金額を口座に入金する必要があります。\n当日24時前までの入金のみ有効です。\n(為替レートが変動するため、24時を過ぎるとキャンセルされます)",
  GuideToolTip6:
    "入金完了後にキャンセルま たは返金をリクエストした場合、1%の手数料が 差し引かれた金額が返金されます。",
  GotoKlook: "klookへアクセス",
  HighlightText: "日24時",
  GuideBill: "紙幣",
  GuideCoin: "硬貨",
  GuideOldBank: "古い紙幣",
  GuideNewBank: "新しい紙幣",
};
export const MapPageJP = {
  서울: "Seoul",
  부산: "Busan",
  경기: "Gyeonggi",
  인천: "Incheon",
  MapList: "List",
  MapMap: "Map",
  MapOpenTime: "営業時間",
  MapMapButton: "地图",
  MapLength: "箇所の拠点",
  MapForiegn: "Foriegn Exchange",
};
export const InqeryPageJP = {
  InqueryTitle1: "MoneyPlex導入に関するお",
  InqueryTitle2: "問い合わせを簡単にどうぞ！",
  InqueryFormName: "会社名",
  InqueryFormNumber: "連絡先",
  InqueryFormPrivate: "個人情報収集同意",
  InqueryPlaceHolderName: "会社名を入力してください",
  InqueryPlaceHolderNumber: "連絡先を入力してください。",
  InqueryPrivateText:
    "入力された情報は、指定された目的以外では使用されません。",
  InqueryApply: "申し込む",
  CSTitle: "何かお手伝いできますか？",
  CSPlaceHolder: "気になることがあれば、キーワードで検索してみてください。",
  InqueryCheckText: "個人情報収集同意（必須）",
  CSFAQ: "よくある質問",
  CsAnnouncements: "お知らせ",
  CSTotal: "Toal",
  CSKiosk: "Kiosk",
  CSETC: "ETC",

  CSMoneyplex: "MoneyPlex APP",
  InquerySuccess: "お問い合わせが正常に送信されました。",
  CSNotFound: "検索結果がありません。",
  AnnounceNotFound: "お知らせはありません。",
};

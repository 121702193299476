import React from "react";
import {
  fontSize24,
  fontSize34,
  fontSize32,
  fontSize40,
  fontSize30,
} from "const/fontSize";
import KioskButtonBack from "asset/image/MobileKioskFind.png";
import Arrow from "asset/image/KioskArrow.png";
import B2Bicon1 from "asset/image/B2Bicon1.png";
import B2Bicon3 from "asset/image/B2Bicon2.png";
import B2Bicon2 from "asset/image/CardIcon.png";
import B2Bicon5 from "asset/image/B2Bicon4.png";
import B2Bicon4 from "asset/image/B2Bicon5.png";
import string from "locales";
import { LanguageProps } from "types/Type";
import { Link } from "react-router-dom";

export default function B2BInfo({ userLanguage }: LanguageProps) {
  return (
    <div className="text-center flex items-center justify-center flex-col mb-100 px-25">
      <div className={`text-center ${fontSize40} text-primary font-bold mt-80`}>
        {string[userLanguage].MoneyplexBusinessTitle}
      </div>
      <div className={`${fontSize34} mt-30 mb-50`}>
        {string[userLanguage].MoneyplexBusinessBody}
      </div>
      <Link to={"/kiosklocation"}>
        <div
          className="w-[300px] h-60 p-5 2xl:w-[500px] 2xl:h-100 xl:w-[480px] xl:h-90 lg:w-[450px] lg:h-80"
          style={{
            backgroundImage: `url(${KioskButtonBack})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: 10,
            height: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 50,
          }}
        >
          <div
            className={` ${fontSize30} flex justify-center items-center gap-x-10 font-bold text-white`}
          >
            {string[userLanguage].MoneyplexFindKiosk}
            <img
              style={{
                objectFit: "cover",
                width: "10%",
              }}
              src={Arrow}
            />
          </div>
        </div>
      </Link>
      <div className="flex flex-col gap-y-30  gap-x-26   lg:flex-row px-[5%]">
        <div
          className={`${fontSize24} text-start p-20 rounded-30 min-w-200 w-full lg:w-[20%]`}
          style={{
            boxShadow: "0px 3px 19px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={B2Bicon2} width={57} />
          <div className="my-10 font-bold">
            {string[userLanguage].MoneyplexBenefitTitle2}
          </div>
          <div className=" text-[#696D72]  leading-35">
            {string[userLanguage].MoneyplexBenefitBody2}
          </div>
        </div>
        <div
          className={`${fontSize24} text-start p-20 rounded-30 min-w-200 w-full lg:w-[20%]`}
          style={{
            boxShadow: "0px 3px 19px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={B2Bicon1} width={57} />
          <div className="my-10 font-bold">
            {string[userLanguage].MoneyplexBenefitTitle1}
          </div>
          <div className="text-[#696D72] leading-35">
            {string[userLanguage].MoneyplexBenefitBody1}
          </div>
        </div>

        <div
          className={`${fontSize24} text-start p-20 rounded-30 min-w-200 w-full lg:w-[20%]`}
          style={{
            boxShadow: "0px 3px 19px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={B2Bicon3} width={57} />
          <div className="my-10 font-bold">
            {string[userLanguage].MoneyplexBenefitTitle3}
          </div>
          <div className="text-[#696D72]  leading-35">
            {string[userLanguage].MoneyplexBenefitBody3}
          </div>
        </div>
        <div
          className={`${fontSize24} text-start p-20 rounded-30 min-w-200 w-full lg:w-[20%]`}
          style={{
            boxShadow: "0px 3px 19px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={B2Bicon4} width={57} />
          <div className="my-10 font-bold">
            {string[userLanguage].MoneyplexBenefitTitle4}
          </div>
          <div className="text-[#696D72]  leading-35">
            {string[userLanguage].MoneyplexBenefitBody4}
          </div>
        </div>
        <div
          className={`${fontSize24} text-start p-20 rounded-30 min-w-200 w-full lg:w-[20%]`}
          style={{
            boxShadow: "0px 3px 19px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={B2Bicon5} width={57} />
          <div className="my-10 font-bold">
            {string[userLanguage].MoneyplexBenefitTitle5}
          </div>
          <div className="text-[#696D72]  leading-35">
            {string[userLanguage].MoneyplexBenefitBody5}
          </div>
        </div>
      </div>
    </div>
  );
}

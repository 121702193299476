import React from "react";
import KioskBackgrundImg from "asset/image/KioskBackgrundImg.png";
import KioskLocation from "asset/image/mainLocationPin.png";
import { LanguageProps } from "types/Type";
import string from "locales";
export default function M_KioskLocation({ userLanguage }: LanguageProps) {
  return (
    <div className="">
      <img src={KioskBackgrundImg} />
      <div className="flex flex-col justify-center items-center my-38 px-24">
        <img src={KioskLocation} width={25} height={25} />
        <span className="text-16 font-bold inline-block leading-23 mt-8">
          {string[userLanguage].kioskSetup}
        </span>
        <span className="text-14 font-bold inline-block leading-23">
          {string[userLanguage].mainKioskFind}
        </span>
        <button className="p-10 bg-primary w-full text-white font-bold leading-20 rounded-10 mt-8">
          {string[userLanguage].KioskFindDirect}
        </button>
      </div>
    </div>
  );
}
